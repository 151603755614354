export default (state = {
    getRequestHistory: [],
    getRequestHistoryLoading: false,
    getRequestHistoryMessage: '',
    deleteRequestLoading: false,
    deleteRequestMessage: '',
    updateRequestLoading: false,
    updateRequestMessage: ''
}, action) => {
    switch (action.type) {
        case 'GET_REQUEST_HISTORY':
            return {
                ...state,
                getRequestHistory: action.payload,
                getRequestHistoryLoading: false,
                getRequestHistoryMessage: ''
            };
        case 'GET_REQUEST_HISTORY_LOADING':
            return {
                ...state,
                getRequestHistoryLoading: true,
                getRequestHistoryMessage: ''
            };
        case 'GET_REQUEST_HISTORY_MESSAGE':
            return {
                ...state,
                getRequestHistoryLoading: false,
                getRequestHistoryMessage: action.payload
            };
        case 'DELETE_REQUEST_HISTORY':
            return {
                ...state,
                deleteRequestLoading: false,
                deleteRequestMessage: ''
            };
        case 'DELETE_REQUEST_HISTORY_LOADING':
            return {
                ...state,
                deleteRequestLoading: true,
                deleteRequestMessage: ''
            };
        case 'DELETE_REQUEST_HISTORY_MESSAGE':
            return {
                ...state,
                deleteRequestLoading: false,
                deleteRequestMessage: action.payload
            };
        case 'UPDATE_REQUEST_HISTORY':
            return {
                ...state,
                updateRequestLoading: false,
                updateRequestMessage: ''
            };
        case 'UPDATE_REQUEST_HISTORY_LOADING':
            return {
                ...state,
                updateRequestLoading: true,
                updateRequestMessage: ''
            };
        case 'UPDATE_REQUEST_HISTORY_MESSAGE':
            return {
                ...state,
                updateRequestLoading: false,
                updateRequestMessage: action.payload
            };
        default:
            return state;
    }
};
