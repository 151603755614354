export default (state = {
  getUserUniform: [],
  getUserUniformLoading: false,
  getUserUniformMessage: '',
  addUserUniformLoading: false,
  addUserUniformMessage: '',
  updateUserUniformLoading: false,
  updateUserUniformMessage: '',
  deleteUserUniformLoading: false,
  deleteUserUniformMessage: ''
}, action) => {
  switch (action.type) {
    case 'GET_USER_UNIFORM':
      return {
        ...state,
        getUserUniform: action.payload,
        getUserUniformLoading: false,
        getUserUniformMessage: ''
      };
    case 'GET_USER_UNIFORM_LOADING':
      return {
        ...state,
        getUserUniformLoading: true,
        getUserUniformMessage: ''
      };
    case 'GET_USER_UNIFORM_MESSAGE':
      return {
        ...state,
        getUserUniformLoading: false,
        getUserUniformMessage: action.payload
      };
    case 'ADD_USER_UNIFORM':
      return {
        ...state,
        addUserUniformLoading: false,
        addUserUniformMessage: ''
      };
    case 'ADD_USER_UNIFORM_LOADING':
      return {
        ...state,
        addUserUniformLoading: true,
        addUserUniformMessage: ''
      };
    case 'ADD_USER_UNIFORM_MESSAGE':
      return {
        ...state,
        addUserUniformLoading: false,
        addUserUniformMessage: action.payload
      };
    case 'UPDATE_USER_UNIFORM':
      return {
        ...state,
        updateUserUniformLoading: false,
        updateUserUniformMessage: ''
      };
    case 'UPDATE_USER_UNIFORM_LOADING':
      return {
        ...state,
        updateUserUniformLoading: true,
        updateUserUniformMessage: ''
      };
    case 'UPDATE_USER_UNIFORM_MESSAGE':
      return {
        ...state,
        updateUserUniformLoading: false,
        updateUserUniformMessage: action.payload
      };
    case 'DELETE_USER_UNIFORM':
      return {
        ...state,
        deleteUserUniformLoading: false,
        deleteUserUniformMessage: ''
      };
    case 'DELETE_USER_UNIFORM_LOADING':
      return {
        ...state,
        deleteUserUniformLoading: true,
        deleteUserUniformMessage: ''
      };
    case 'DELETE_USER_UNIFORM_MESSAGE':
      return {
        ...state,
        deleteUserUniformLoading: false,
        deleteUserUniformMessage: action.payload
      };
    default:
      return state;
  }
};
