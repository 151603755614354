import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";

import HomePage from './HomePage';
import {
    login,
    loginLocation,
} from '../redux/actions';

const publicUrl = process.env.PUBLIC_URL;

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nip: '',
            password: '',
            nipMsg: '',
            passwordMsg: '',
            loginAs: 'admin'
        }
    }

    onLogin() {
        if (!this.state.nip) {
            this.setState({
                nipMsg: 'Isi NIP anda'
            });
        }
        if (!this.state.password) {
            this.setState({
                passwordMsg: 'Isi Password anda'
            });
        }
        if (this.state.nip && this.state.password) {
            if (this.state.loginAs === 'admin') {
                this.props.login(this.state.nip, this.state.password);
            } else {
                this.props.loginLocation(this.state.nip, this.state.password);
            }
        }
    }

    render() {
        if (this.props.user.isAuth) {
            return (
                <Router basename={publicUrl}>
                    <Switch>
                        <Route exact path="/" component={HomePage} />
                    </Switch>
                    <Redirect to='/' />
                </Router>
            );
        } else {
            return (
                <div>
                    <div className="page-wrapper">
                        <div className="page-content--bge5">
                            <div className="container">
                                <div className="login-wrap">
                                    <div className="login-content">
                                        <div className="login-logo">
                                            <a href="/">
                                                <img src="images/icon/logo.png" alt="CoolAdmin" style={{ width: '30%' }} />
                                            </a>
                                        </div>
                                        <p style={{ fontSize: '24px', textAlign: 'center' }}>PT. Bakri Karya Sarana</p>
                                        <div className="login-form">
                                            {this.props.user.loginMessage && <div className="alert alert-danger" role="alert">
                                                {this.props.user.loginMessage}
                                            </div>}
                                            {this.props.location.loginLocationMessage && <div className="alert alert-danger" role="alert">
                                                {this.props.location.loginLocationMessage}
                                            </div>}
                                            <form onSubmit={(e) => {
                                                e.preventDefault();
                                                this.onLogin();
                                            }}>
                                                {this.state.loginAs === 'admin' ? (
                                                    <div className="form-group">
                                                        <label style={{ display: 'inline' }}>NIP {this.state.nipMsg && <label style={{ display: 'inline', color: 'red' }}>{`- ${this.state.nipMsg}`}</label>}</label>
                                                        <input onChange={(e) => this.setState({ nip: e.target.value, nipMsg: '' })} className="au-input au-input--full" type="text" name="nip" placeholder="NIP" />
                                                    </div>
                                                ) : (
                                                    <div className="form-group">
                                                        <label style={{ display: 'inline' }}>Email {this.state.nipMsg && <label style={{ display: 'inline', color: 'red' }}>{`- ${this.state.nipMsg}`}</label>}</label>
                                                        <input onChange={(e) => this.setState({ nip: e.target.value, nipMsg: '' })} className="au-input au-input--full" type="text" name="nip" placeholder="Email" />
                                                    </div>
                                                )}
                                                <div className="form-group">
                                                    <label style={{ display: 'inline' }}>Password {this.state.passwordMsg && <label style={{ display: 'inline', color: 'red' }}>{`- ${this.state.passwordMsg}`}</label>}</label>
                                                    <input onChange={(e) => this.setState({ password: e.target.value, passwordMsg: '' })} className="au-input au-input--full" type="password" name="password" placeholder="Password" />
                                                </div>
                                                <button className="au-btn au-btn--block au-btn--green m-b-20" type="submit">sign in</button>
                                                {this.state.loginAs === 'admin' ? (
                                                    <button onClick={() => this.setState({
                                                        ...this.state,
                                                        loginAs: 'client'
                                                    })} className="au-btn au-btn--block au-btn--blue m-b-20" type="button">sign in as client</button>
                                                ) : (
                                                    <button onClick={() => this.setState({
                                                        ...this.state,
                                                        loginAs: 'admin'
                                                    })} className="au-btn au-btn--block au-btn--blue m-b-20" type="button">sign in as admin</button>
                                                )}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = state => ({
    user: state.user,
    location: state.location,
});

export default connect(mapStateToProps, {
    login,
    loginLocation
})(LoginPage);
