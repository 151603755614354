import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

import {
    getAttendance,
    getAttendanceReport,
    getLocation
} from '../redux/actions';
import {
    domain
} from '../constants';

class AttendancePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            update: false,
            from: new Date(),
            to: new Date(),
            filterLocation: ''
        };
    }

    componentDidMount() {
        this.reload();
    }

    reload = () => {
        if (this.props.user.userData.isClient) {
            return this.props.getAttendance(this.state.from, this.state.to, this.props.user.userData.location_id);
        }
        if (this.state.filterLocation !== '') {
            this.props.getAttendance(this.state.from, this.state.to, this.state.filterLocation);
        }
        this.props.getLocation();
    }

    render() {
        return (
            <div className="container-fluid">
                {this.props.attendance.addLocationMessage && <div className="alert alert-danger" role="alert">
                    {this.props.attendance.addLocationMessage}
                </div>}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Dari</label>
                        <div className="form-control">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    format='DD-MM-YYYY'
                                    value={this.state.from}
                                    onChange={async (date) => {
                                        await this.setState({ from: new Date(date.toISOString()) });
                                        this.reload();
                                    }}
                                    animateYearScrolling
                                    inputVariant='outlined'
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Sampai</label>
                        <div className="form-control">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    format='DD-MM-YYYY'
                                    value={this.state.to}
                                    onChange={async (date) => {
                                        await this.setState({ to: new Date(date.toISOString()) });
                                        this.reload();
                                    }}
                                    animateYearScrolling
                                    inputVariant='outlined'
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    {!this.props.user.userData.isClient && (
                        <div className="form-group" style={{ marginRight: '8px' }}>
                            <label style={{ display: 'inline' }}>Filter Lokasi</label>
                            <select value={this.state.filterLocation} className="form-control" onChange={async (e) => {
                                await this.setState({ filterLocation: e.target.value });
                                this.reload();
                            }}>
                                <option value="" >Pilih Lokasi</option>
                                {this.props.location.getLocation.map(el => <option key={el._id} value={el._id} >{el.name}</option>)}
                            </select>
                        </div>
                    )}
                </div>
                <button className="table-data__tool-right" onClick={() => this.props.getAttendanceReport(`${domain}/api/attendance/report/employee?from=${moment(this.state.from).format('DD-MM-YYYY')}&to=${moment(this.state.to).format('DD-MM-YYYY')}&locationId=${this.state.filterLocation}&overtime=no&format=json`, this.state.from, this.state.to)}>
                    <div className="au-btn au-btn-icon au-btn--green au-btn--small">
                        <i className="zmdi zmdi-download"></i>Download Laporan
                    </div>
                </button>
                <button className="table-data__tool-right" onClick={() => this.props.getAttendanceReport(`${domain}/api/attendance/report/employee?from=${moment(this.state.from).format('DD-MM-YYYY')}&to=${moment(this.state.to).format('DD-MM-YYYY')}&locationId=${this.state.filterLocation}&overtime=yes&format=json`, this.state.from, this.state.to)}>
                    <div className="au-btn au-btn-icon au-btn--green au-btn--small">
                        <i className="zmdi zmdi-download"></i>Download Laporan Overtime
                    </div>
                </button>
                <br /><br />
                {this.state.add || this.state.update ? <form onSubmit={(e) => {
                    e.preventDefault();
                    this.onAddLocation();
                }}>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Nama<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                        <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} className="au-input au-input--full" type="text" name="nama" placeholder="Nama" />
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Latitude<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                        <input value={this.state.latitude} onChange={(e) => this.setState({ latitude: e.target.value })} className="au-input au-input--full" type="text" name="latitude" placeholder="Latitude" />
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Longitude<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                        <input value={this.state.longitude} onChange={(e) => this.setState({ longitude: e.target.value })} className="au-input au-input--full" type="text" name="longitude" placeholder="Longitude" />
                    </div>
                    <button className="au-btn au-btn--block au-btn--green m-b-20" type="submit">{this.state.add ? 'tambah' : 'update'}</button>
                    <button className="au-btn au-btn--block au-btn--blue m-b-20" type="submit" onClick={() => this.setState((state, props) => ({ add: false, update: false, id: '', name: '', latitude: 0, longitude: 0 }))}>batal</button>
                </form> : null}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive table--no-card m-b-30">
                            <table className="table table-borderless table-striped table-earning">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Tanggal</th>
                                        <th>Nama</th>
                                        <th>Tipe</th>
                                        <th>Waktu</th>
                                        <th>Terlambat</th>
                                        <th>Jam Kerja</th>
                                        <th>Status</th>
                                        <th>Lokasi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.attendance.getAttendance.map((el, index) => (
                                        <tr key={el.id}>
                                            <td>{index + 1}</td>
                                            <td>{moment(el.date2).format('DD MMMM YYYY')}</td>
                                            <td>{el.userId ? el.userId.name : ''}</td>
                                            <td>{el.type}</td>
                                            <td>{moment(el.startTime).format('HH:mm')} - {el.endTime ? moment(el.endTime).format('HH:mm') : ''}</td>
                                            <td>{el.late} menit ({`${el.timeIn} - ${el.timeOut}`})</td>
                                            <td>{el.workingHour}</td>
                                            <td>{el.status ? <i style={{ padding: 8, backgroundColor: '#00C851', color: 'white', borderRadius: 15 }} className="zmdi zmdi-check"></i> : <i style={{ padding: 8, backgroundColor: '#ff4444', color: 'white', borderRadius: 15 }} className="zmdi zmdi-alert-triangle"></i>}</td>
                                            <td>{el.locationId ? el.locationId.name : ''}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    attendance: state.attendance,
    location: state.location
});

export default connect(mapStateToProps, {
    getAttendance,
    getAttendanceReport,
    getLocation
})(AttendancePage);
