import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';

import {
    getAnnouncement,
    addAnnouncement,
    deleteAnnouncement
} from '../redux/actions';

class AnnouncementPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            add: false
        };
    }

    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.props.getAnnouncement();
    }

    render() {
        return (
            <div className="container-fluid">
                {this.props.announcement.addAnnouncementMessage && <div className="alert alert-danger" role="alert">
                    {this.props.announcement.addAnnouncementMessage}
                </div>}
                <div className="table-data__tool-right">
                    <button onClick={() => this.setState((state, props) => ({ add: !state.add, title: '', description: '' }))} className="au-btn au-btn-icon au-btn--green au-btn--small">
                        <i className="zmdi zmdi-plus"></i>tambah pengumuman
                </button>
                </div>
                <br />
                {this.state.add || this.state.update ? <form onSubmit={(e) => {
                    e.preventDefault();
                    this.props.addAnnouncement(this.state.title, this.state.description, this.props.user.userData.token, this);
                }}>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Judul<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                        <input value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} className="au-input au-input--full" type="text" name="title" placeholder="Judul" />
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Deskripsi<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                        <textarea value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} className="au-input au-input--full" type="text" name="description" />
                    </div>
                    <button className="au-btn au-btn--block au-btn--green m-b-20" type="submit">tambah</button>
                </form> : null}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive table--no-card m-b-30">
                            <table className="table table-borderless table-striped table-earning">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Judul</th>
                                        <th>Deskripsi</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.announcement.getAnnouncement.map((el, index) => (
                                        <tr key={el._id}>
                                            <td>{index + 1}</td>
                                            <td>{el.title}</td>
                                            <td>{el.description.length >= 50 ? `${el.description.substr(0, 50)}...` : el.description}</td>
                                            <td>
                                                <div className="table-data-feature">
                                                    <button onClick={() => this.props.deleteAnnouncement(el._id, this.props.user.userData.token, this)} className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                                                        <i className="zmdi zmdi-delete"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    announcement: state.announcement,
    user: state.user
});

export default connect(mapStateToProps, {
    getAnnouncement,
    addAnnouncement,
    deleteAnnouncement
})(AnnouncementPage);
