export default (state = {
    getUniform: [],
    getUniformLoading: false,
    getUniformMessage: '',
    addUniformLoading: false,
    addUniformMessage: '',
    updateUniformLoading: false,
    updateUniformMessage: '',
    deleteUniformLoading: false,
    deleteUniformMessage: '',
}, action) => {
    switch (action.type) {
        case 'GET_UNIFORM':
            return {
                ...state,
                getUniform: action.payload,
                getUniformLoading: false,
                getUniformMessage: ''
            };
        case 'GET_UNIFORM_LOADING':
            return {
                ...state,
                getUniformLoading: true,
                getUniformMessage: ''
            };
        case 'GET_UNIFORM_MESSAGE':
            return {
                ...state,
                getUniformLoading: false,
                getUniformMessage: action.payload
            };
        case 'ADD_UNIFORM':
            return {
                ...state,
                addUniformLoading: false,
                addUniformMessage: ''
            };
        case 'ADD_UNIFORM_LOADING':
            return {
                ...state,
                addUniformLoading: true,
                addUniformMessage: ''
            };
        case 'ADD_UNIFORM_MESSAGE':
            return {
                ...state,
                addUniformLoading: false,
                addUniformMessage: action.payload
            };
        case 'UPDATE_UNIFORM':
            return {
                ...state,
                updateUniformLoading: false,
                updateUniformMessage: ''
            };
        case 'UPDATE_UNIFORM_LOADING':
            return {
                ...state,
                updateUniformLoading: true,
                updateUniformMessage: ''
            };
        case 'UPDATE_UNIFORM_MESSAGE':
            return {
                ...state,
                updateUniformLoading: false,
                updateUniformMessage: action.payload
            };
        case 'DELETE_UNIFORM':
            return {
                ...state,
                deleteUniformLoading: false,
                deleteUniformMessage: ''
            };
        case 'DELETE_UNIFORM_LOADING':
            return {
                ...state,
                deleteUniformLoading: true,
                deleteUniformMessage: ''
            };
        case 'DELETE_UNIFORM_MESSAGE':
            return {
                ...state,
                deleteUniformLoading: false,
                deleteUniformMessage: action.payload
            };
        default:
            return state;
    }
};
