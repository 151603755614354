export default (state = {
    getAnnouncement: [],
    getAnnouncementLoading: false,
    getAnnouncementMessage: '',
    deleteAnnouncementLoading: false,
    deleteAnnouncementMessage: '',
    addAnnouncementLoading: false,
    addAnnouncementMessage: ''
}, action) => {
    switch (action.type) {
        case 'GET_ANNOUNCEMENT':
            return {
                ...state,
                getAnnouncement: action.payload,
                getAnnouncementLoading: false,
                getAnnouncementMessage: ''
            };
        case 'GET_ANNOUNCEMENT_LOADING':
            return {
                ...state,
                getAnnouncementLoading: true,
                getAnnouncementMessage: ''
            };
        case 'GET_ANNOUNCEMENT_MESSAGE':
            return {
                ...state,
                getAnnouncementLoading: false,
                getAnnouncementMessage: action.payload
            };
        case 'DELETE_ANNOUNCEMENT':
            return {
                ...state,
                deleteAnnouncementLoading: false,
                deleteAnnouncementMessage: ''
            }
        case 'DELETE_ANNOUNCEMENT_LOADING':
            return {
                ...state,
                deleteAnnouncementLoading: true,
                deleteAnnouncementMessage: ''
            }
        case 'DELETE_ANNOUNCEMENT_MESSAGE':
            return {
                ...state,
                deleteAnnouncementLoading: false,
                deleteAnnouncementMessage: action.payload
            }
        case 'ADD_ANNOUNCEMENT':
            return {
                ...state,
                addAnnouncementLoading: false,
                addAnnouncementMessage: ''
            }
        case 'ADD_ANNOUNCEMENT_LOADING':
            return {
                ...state,
                addAnnouncementLoading: true,
                addAnnouncementMessage: ''
            }
        case 'ADD_ANNOUNCEMENT_MESSAGE':
            return {
                ...state,
                addAnnouncementLoading: false,
                addAnnouncementMessage: action.payload
            }
        default:
            return state;
    }
};
