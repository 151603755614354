export default (state = {
  getRequestUniform: [],
  getRequestUniformLoading: false,
  getRequestUniformMessage: '',
  addRequestUniformLoading: false,
  addRequestUniformMessage: '',
  updateRequestUniformLoading: false,
  updateRequestUniformMessage: '',
  deleteRequestUniformLoading: false,
  deleteRequestUniformMessage: '',
  updateRequestUniformStatusLoading: false,
  updateRequestUniformStatusMessage: '',
}, action) => {
  switch (action.type) {
    case 'GET_REQUEST_UNIFORM':
      return {
        ...state,
        getRequestUniform: action.payload,
        getRequestUniformLoading: false,
        getRequestUniformMessage: ''
      };
    case 'GET_REQUEST_UNIFORM_LOADING':
      return {
        ...state,
        getRequestUniformLoading: true,
        getRequestUniformMessage: ''
      };
    case 'GET_REQUEST_UNIFORM_MESSAGE':
      return {
        ...state,
        getRequestUniformLoading: false,
        getRequestUniformMessage: action.payload
      };
    case 'ADD_REQUEST_UNIFORM':
      return {
        ...state,
        addRequestUniformLoading: false,
        addRequestUniformMessage: ''
      };
    case 'ADD_REQUEST_UNIFORM_LOADING':
      return {
        ...state,
        addRequestUniformLoading: true,
        addRequestUniformMessage: ''
      };
    case 'ADD_REQUEST_UNIFORM_MESSAGE':
      return {
        ...state,
        addRequestUniformLoading: false,
        addRequestUniformMessage: action.payload
      };
    case 'UPDATE_REQUEST_UNIFORM':
      return {
        ...state,
        updateRequestUniformLoading: false,
        updateRequestUniformMessage: ''
      };
    case 'UPDATE_REQUEST_UNIFORM_LOADING':
      return {
        ...state,
        updateRequestUniformLoading: true,
        updateRequestUniformMessage: ''
      };
    case 'UPDATE_REQUEST_UNIFORM_MESSAGE':
      return {
        ...state,
        updateRequestUniformLoading: false,
        updateRequestUniformMessage: action.payload
      };
    case 'DELETE_REQUEST_UNIFORM':
      return {
        ...state,
        deleteRequestUniformLoading: false,
        deleteRequestUniformMessage: ''
      };
    case 'DELETE_REQUEST_UNIFORM_LOADING':
      return {
        ...state,
        deleteRequestUniformLoading: true,
        deleteRequestUniformMessage: ''
      };
    case 'DELETE_REQUEST_UNIFORM_MESSAGE':
      return {
        ...state,
        deleteRequestUniformLoading: false,
        deleteRequestUniformMessage: action.payload
      };
    case 'UPDATE_REQUEST_UNIFORM_STATUS':
      return {
        ...state,
        updateRequestUniformStatusLoading: false,
        updateRequestUniformStatusMessage: ''
      };
    case 'UPDATE_REQUEST_UNIFORM_STATUS_LOADING':
      return {
        ...state,
        updateRequestUniformStatusLoading: true,
        updateRequestUniformStatusMessage: ''
      };
    case 'UPDATE_REQUEST_UNIFORM_STATUS_MESSAGE':
      return {
        ...state,
        updateRequestUniformStatusLoading: false,
        updateRequestUniformStatusMessage: action.payload
      };
    default:
      return state;
  }
};
