import React, {
  Component
} from 'react';
import {
  connect
} from 'react-redux';

import {
  updateUniform,
  addUniform,
  deleteUniform,
  getUniform
} from '../redux/actions';

class UniformPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
      update: false,
      id: '',
      name: '',
      unit: '',
      price: '',
      type: '',
    };
  }

  reload = () => {
    this.props.getUniform(this.state.type, this.props.user.userData.token);
  }

  render() {
    return (
      <div className="container-fluid">
        {this.props.uniform.addUniformMessage && <div className="alert alert-danger" role="alert">
          {this.props.uniform.addUniformMessage}
        </div>}
        <div className="table-data__tool-right">
          <button onClick={() => this.setState((state, props) => ({ add: !state.add, update: false, id: '', name: '', price: '', unit: '' }))} className="au-btn au-btn-icon au-btn--green au-btn--small">
            <i className="zmdi zmdi-plus"></i>tambah uniform
              </button>
        </div>
        <br />
        {this.state.add || this.state.update ? <form onSubmit={(e) => {
          e.preventDefault();
          if (this.state.update) {
            return this.props.updateUniform(this.state.id, this.state.name, this.state.unit, this.state.price, this.state.type, this, this.props.user.userData.token);
          }
          this.props.addUniform(this.state.name, this.state.unit, this.state.price, this.state.type, this, this.props.user.userData.token);
        }}>
          <div className="form-group">
            <label style={{ display: 'inline' }}>Nama<label style={{ display: 'inline', color: 'red' }}> *</label></label>
            <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} className="au-input au-input--full" type="text" name="name" placeholder="Nama" />
          </div>
          <div className="form-group">
            <label style={{ display: 'inline' }}>Unit<label style={{ display: 'inline', color: 'red' }}> *</label></label>
            <input value={this.state.unit} onChange={(e) => this.setState({ unit: e.target.value })} className="au-input au-input--full" type="text" name="unit" placeholder="Unit" />
          </div>
          <div className="form-group">
            <label style={{ display: 'inline' }}>Harga<label style={{ display: 'inline', color: 'red' }}> *</label></label>
            <input value={this.state.price} onChange={(e) => this.setState({ price: e.target.value })} className="au-input au-input--full" type="text" name="price" placeholder="Harga" />
          </div>
          <div className="form-group">
            <label style={{ display: 'inline' }}>Lokasi Harga<label style={{ display: 'inline', color: 'red' }}> *</label></label>
            <select value={this.state.type} className="form-control" onChange={async e => {
              await this.setState({ type: e.target.value });
              this.reload();
            }}>
              <option value="" >Pilih Lokasi Harga</option>
              <option value="Batam" >Batam</option>
              <option value="Jakarta" >Jakarta</option>
              <option value="Pekanbaru" >Pekanbaru</option>
              <option value="Padang" >Padang</option>
              <option value="Medan" >Medan</option>
              <option value="Tanjungpinang" >Tanjungpinang</option>
              <option value="Bandung" >Bandung</option>
              <option value="Bali" >Bali</option>
            </select>
          </div>
          <button className="au-btn au-btn--block au-btn--green m-b-20" type="submit">{this.state.add ? 'tambah' : 'update'}</button>
          <button className="au-btn au-btn--block au-btn--blue m-b-20" type="submit" onClick={() => this.setState((state, props) => ({ add: false, update: false, id: '', name: '', price: '', unit: '' }))}>batal</button>
        </form> : null}
        <div className="form-group">
          <label style={{ display: 'inline' }}>Lokasi Harga</label>
          <select value={this.state.type} className="form-control" onChange={async e => {
            await this.setState({ type: e.target.value });
            this.reload();
          }}>
            <option value="" >Pilih Lokasi Harga</option>
            <option value="Batam" >Batam</option>
            <option value="Jakarta" >Jakarta</option>
            <option value="Pekanbaru" >Pekanbaru</option>
            <option value="Padang" >Padang</option>
            <option value="Medan" >Medan</option>
            <option value="Tanjungpinang" >Tanjungpinang</option>
            <option value="Bandung" >Bandung</option>
            <option value="Bali" >Bali</option>
          </select>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive table--no-card m-b-30">
              <table className="table table-borderless table-striped table-earning">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama</th>
                    <th>Unit</th>
                    <th>Harga</th>
                    <th>Tipe</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.uniform.getUniform.map((el, index) => (
                    <tr key={el._id}>
                      <td>{index + 1}</td>
                      <td>{el.name}</td>
                      <td>{el.unit}</td>
                      <td>Rp. {el.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                      <td>{el.type}</td>
                      <td>
                        <div className="table-data-feature">
                          <button onClick={() => {
                            window.scrollTo(0, 0);
                            this.setState({ update: true, add: false, name: el.name, price: el.price, unit: el.unit, type: el.type, id: el._id })
                          }} className="item" data-toggle="tooltip" data-placement="top" title="Update">
                            <i className="zmdi zmdi-edit"></i>
                          </button>
                          <button onClick={() => this.props.deleteUniform(el._id, this, this.props.user.userData.token)} className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                            <i className="zmdi zmdi-delete"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  uniform: state.uniform,
  user: state.user
});

export default connect(mapStateToProps, {
  updateUniform,
  addUniform,
  deleteUniform,
  getUniform
})(UniformPage);
