import {
    domain
} from '../../constants';

export const getAnnouncement = () => dispatch => {
    dispatch({
        type: 'GET_ANNOUNCEMENT_LOADING'
    });
    fetch(`${domain}/api/announcement`, {
        method: 'GET'
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                return dispatch({
                    type: 'GET_ANNOUNCEMENT',
                    payload: response.data
                });
            } else if (result.status === 500) {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'GET_ANNOUNCEMENT_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'GET_ANNOUNCEMENT_MESSAGE',
                payload: error.toString()
            });
        });
};

export const deleteAnnouncement = (id, token, those) => dispatch => {
    dispatch({
        type: 'DELETE_ANNOUNCEMENT_LOADING'
    });
    fetch(`${domain}/api/announcement/${id}`, {
        method: 'DELETE',
        headers: {
            'Authorization': token
        }
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                those.reload();
                return dispatch({
                    type: 'DELETE_ANNOUNCEMENT'
                });
            } else if (result.status === 500) {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'DELETE_ANNOUNCEMENT_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'DELETE_ANNOUNCEMENT_MESSAGE',
                payload: error.toString()
            });
        });
};

export const addAnnouncement = (title, description, token, those) => dispatch => {
    dispatch({
        type: 'ADD_ANNOUNCEMENT_LOADING'
    });
    fetch(`${domain}/api/announcement`, {
        method: 'POST',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            title,
            description
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 201) {
                those.reload();
                those.setState({
                    title: '',
                    description: '',
                    add: false
                })
                return dispatch({
                    type: 'ADD_ANNOUNCEMENT'
                });
            } else if (result.status === 500) {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'ADD_ANNOUNCEMENT_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'ADD_ANNOUNCEMENT_MESSAGE',
                payload: error.toString()
            });
        });
}
