export default (state = {
    userData: {},
    isAuth: false,
    loginLoading: false,
    loginMessage: '',
    getUser: [],
    getUserLoading: false,
    getUserMessage: '',
    addUserLoading: false,
    addUserMessage: '',
    updateUserLoading: false,
    updateUserMessage: '',
    deleteUserLoading: false,
    deleteUserMessage: '',
    getSupervisor: [],
    getSupervisorLoading: false,
    getSupervisorMessage: ''
}, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                userData: action.payload,
                isAuth: true,
                loginLoading: false,
                loginMessage: ''
            };
        case 'LOGIN_LOADING':
            return {
                ...state,
                userData: {},
                isAuth: false,
                loginLoading: true,
                loginMessage: ''
            };
        case 'LOGIN_MESSAGE':
            return {
                ...state,
                userData: {},
                isAuth: false,
                loginLoading: false,
                loginMessage: action.payload
            };
        case 'GET_USER':
            return {
                ...state,
                getUser: action.payload,
                getUserLoading: false,
                getUserMessage: ''
            };
        case 'GET_USER_LOADING':
            return {
                ...state,
                getUserLoading: true,
                getUserMessage: ''
            };
        case 'GET_USER_MESSAGE':
            return {
                ...state,
                getUserLoading: false,
                getUserMessage: action.payload
            };
        case 'ADD_USER':
            return {
                ...state,
                addUserLoading: false,
                addUserMessage: ''
            };
        case 'ADD_USER_LOADING':
            return {
                ...state,
                addUserLoading: true,
                addUserMessage: ''
            };
        case 'ADD_USER_MESSAGE':
            return {
                ...state,
                addUserLoading: false,
                addUserMessage: action.payload
            };
        case 'UPDATE_USER':
            return {
                ...state,
                updateUserLoading: false,
                updateUserMessage: ''
            };
        case 'UPDATE_USER_LOADING':
            return {
                ...state,
                updateUserLoading: true,
                updateUserMessage: ''
            };
        case 'UPDATE_USER_MESSAGE':
            return {
                ...state,
                updateUserLoading: false,
                updateUserMessage: action.payload
            };
        case 'DELETE_USER':
            return {
                ...state,
                deleteUserLoading: false,
                deleteUserMessage: ''
            };
        case 'DELETE_USER_LOADING':
            return {
                ...state,
                deleteUserLoading: true,
                deleteUserMessage: ''
            };
        case 'DELETE_USER_MESSAGE':
            return {
                ...state,
                deleteUserLoading: false,
                deleteUserMessage: action.payload
            };
        case 'GET_SUPERVISOR':
            return {
                ...state,
                getSupervisor: action.payload,
                getSupervisorLoading: false,
                getSupervisorMessage: ''
            };
        case 'GET_SUPERVISOR_LOADING':
            return {
                ...state,
                getSupervisorLoading: true,
                getSupervisorMessage: ''
            };
        case 'GET_SUPERVISOR_MESSAGE':
            return {
                ...state,
                getSupervisorLoading: false,
                getSupervisorMessage: action.payload
            };
        default:
            return state;
    }
};
