export default (state = {
    getItem: [],
    getItemLoading: false,
    getItemMessage: '',
    addItemLoading: false,
    addItemMessage: '',
    updateItemLoading: false,
    updateItemMessage: '',
    deleteItemLoading: false,
    deleteItemMessage: '',
}, action) => {
    switch (action.type) {
        case 'GET_ITEM':
            return {
                ...state,
                getItem: action.payload,
                getItemLoading: false,
                getItemMessage: ''
            };
        case 'GET_ITEM_LOADING':
            return {
                ...state,
                getItemLoading: true,
                getItemMessage: ''
            };
        case 'GET_ITEM_MESSAGE':
            return {
                ...state,
                getItemLoading: false,
                getItemMessage: action.payload
            };
        case 'ADD_ITEM':
            return {
                ...state,
                addItemLoading: false,
                addItemMessage: ''
            };
        case 'ADD_ITEM_LOADING':
            return {
                ...state,
                addItemLoading: true,
                addItemMessage: ''
            };
        case 'ADD_ITEM_MESSAGE':
            return {
                ...state,
                addItemLoading: false,
                addItemMessage: action.payload
            };
        case 'UPDATE_ITEM':
            return {
                ...state,
                updateItemLoading: false,
                updateItemMessage: ''
            };
        case 'UPDATE_ITEM_LOADING':
            return {
                ...state,
                updateItemLoading: true,
                updateItemMessage: ''
            };
        case 'UPDATE_ITEM_MESSAGE':
            return {
                ...state,
                updateItemLoading: false,
                updateItemMessage: action.payload
            };
        case 'DELETE_ITEM':
            return {
                ...state,
                deleteItemLoading: false,
                deleteItemMessage: ''
            };
        case 'DELETE_ITEM_LOADING':
            return {
                ...state,
                deleteItemLoading: true,
                deleteItemMessage: ''
            };
        case 'DELETE_ITEM_MESSAGE':
            return {
                ...state,
                deleteItemLoading: false,
                deleteItemMessage: action.payload
            };
        default:
            return state;
    }
};
