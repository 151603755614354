import {
  domain
} from '../../constants';

export const getRequestSupply = (from, to) => dispatch => {
  dispatch({
    type: 'GET_REQUEST_SUPPLY_LOADING'
  });
  fetch(`${domain}/api/request-supply?from=${from}&to=${to}`, {
    method: 'GET'
  })
    .then(async result => {
      const response = await result.json();
      if (result.status === 200) {
        const newResponse = response.data.map(el => {
          let total = 0
          el.items.forEach(ele => {
            total += ele.subTotal
          });
          return {
            ...el,
            total
          };
        });
        return dispatch({
          type: 'GET_REQUEST_SUPPLY',
          payload: newResponse
        });
      } else {
        return dispatch({
          type: 'GET_REQUEST_SUPPLY_MESSAGE',
          payload: response.message
        });
      }
    })
    .catch(error => {
      return dispatch({
        type: 'GET_REQUEST_SUPPLY_MESSAGE',
        payload: error.toString()
      });
    });
};

export const updateRequestSupply = (id, status, token, reload) => dispatch => {
  dispatch({
    type: 'UPDATE_REQUEST_SUPPLY_LOADING'
  });
  fetch(`${domain}/api/request-supply/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify({
      status
    })
  })
    .then(async result => {
      const response = await result.json();
      if (result.status === 201) {
        reload();
        return dispatch({
          type: 'UPDATE_REQUEST_SUPPLY'
        });
      } else {
        return dispatch({
          type: 'UPDATE_REQUEST_SUPPLY_MESSAGE',
          payload: response.message
        });
      }
    })
    .catch(error => {
      return dispatch({
        type: 'UPDATE_REQUEST_SUPPLY_MESSAGE',
        payload: error.toString()
      });
    });
};
