import moment from 'moment';
import json2xls from 'json2xls/lib/json2xls';
import {
    saveAs
} from 'file-saver';

import {
    domain
} from '../../constants';

export const getAttendance = (from, to, locationId) => dispatch => {
    dispatch({
        type: 'GET_ATTENDANCE_LOADING'
    });
    fetch(`${domain}/api/attendance?from=${moment(from).format('DD-MM-YYYY')}&to=${moment(to).format('DD-MM-YYYY')}&locationId=${locationId}`, {
        method: 'GET'
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                return dispatch({
                    type: 'GET_ATTENDANCE',
                    payload: response.data
                });
            } else if (result.status === 500) {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'GET_ATTENDANCE_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'GET_ATTENDANCE_MESSAGE',
                payload: error.toString()
            });
        });
};

export const getAttendanceReport = (url, from, to) => dispatch => {
    dispatch({
        type: 'GET_ATTENDANCE_REPORT_LOADING'
    });
    fetch(url, {
        method: 'GET'
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                let uniqUser = response.data.filter((thing, index, self) => {
                    return index === self.findIndex((t) => (
                        t.userId._id === thing.userId._id
                    ));
                });
                uniqUser = uniqUser.map(el => {
                    return {
                        userid: el.userId._id,
                        name: el.userId.name,
                        nip: el.userId.nip
                    };
                });
                uniqUser = uniqUser.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                let userAttendance = [];
                uniqUser.forEach(el => {
                    let userAtt = [];
                    response.data.forEach(elAtt => {
                        if (el.userid === elAtt.userId._id) {
                            userAtt.push(elAtt);
                        }
                    });
                    const a = {
                        user: el.name,
                        nip: el.nip,
                        attendance: userAtt
                    };
                    userAttendance.push(a);
                });
                const dateLength = moment.duration(moment(to, 'DD-MM-YYYY').toDate() - moment(from, 'DD-MM-YYYY').toDate()).asDays();
                let dates = [];
                let date = moment(from, 'DD-MM-YYYY').toDate();
                for (let index = 0; index < dateLength + 1; index++) {
                    dates.push(date);
                    date = moment(date).add(1, 'day').toDate();
                }
                userAttendance = userAttendance.map((el, index) => {
                    var obj = {};
                    for (let index = 0; index < dates.length; index++) {
                        const dddd = dates[index];
                        var total = 0;
                        var late = 0;
                        // eslint-disable-next-line no-loop-func
                        el.attendance.forEach(element => {
                            total += element.workingHour;
                            late += element.late;
                            if (moment(element.date2).format('L') === moment(dddd).format('L')) {
                                Object.assign(obj, { [moment(dddd).format('DD MMMM')]: Number(element.workingHour).toFixed(1) });
                            }
                        });
                    }
                    return {
                        Nomor: index + 1,
                        NIK: el.nip,
                        Nama: el.user,
                        ...obj,
                        'Total Jam Kerja': Number(total).toFixed(1),
                        'Total Keterlambatan': Number(late).toFixed(1)
                    };
                });
                const start = moment(from, 'DD-MM-YYYY').startOf('day').toDate();
                const finish = moment(to, 'DD-MM-YYYY').startOf('day').toDate();
                const diff = moment.duration(finish - start).asDays();
                let dummyObj = {};
                for (let index = 0; index <= diff; index++) {
                    Object.assign(dummyObj, { [moment(start).add(index, 'day').format('DD MMMM')]: '-' });
                }
                const dummyAttendance = {
                    Nomor: 0,
                    NIK: 0,
                    Nama: '-',
                    ...dummyObj,
                    'Total Jam Kerja': '-',
                    'Total Keterlambatan': '-'
                };
                userAttendance = [dummyAttendance, ...userAttendance];
                const xlsx = json2xls(userAttendance);
                const decoded = decodeBase64(xlsx);
                const buf = new ArrayBuffer(decoded.length);
                const view = new Uint8Array(buf);
                for (let i = 0; i !== decoded.length; ++i) {
                    view[i] = decoded.charCodeAt(i) & 0xFF;
                }

                const blob = new Blob([buf], {
                    type: 'application/octet-stream'
                });
                saveAs(blob, 'a.xlsx');
                return dispatch({
                    type: 'GET_ATTENDANCE_REPORT',
                    payload: response.data
                });
            } else if (result.status === 500) {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'GET_ATTENDANCE_REPORT_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'GET_ATTENDANCE_REPORT_MESSAGE',
                payload: error.toString()
            });
        });
};

const decodeBase64 = (str) => {
    try {
        return window.atob(str);
    } catch (e) {
        return str;
    }
};
