import {
    domain
} from '../../constants'

export const initialData = () => async dispatch => {
    await localStorage.removeItem('UserData');
    const UserData = await localStorage.getItem('UserData2');
    if (UserData) {
        return dispatch({
            type: 'LOGIN',
            payload: JSON.parse(UserData)
        });
    }
};

export const login = (username, password) => dispatch => {
    dispatch({
        type: 'LOGIN_LOADING'
    });
    fetch(`${domain}/api/user/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            nip: username,
            password
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200 && response.level === 'Admin') {
                await localStorage.setItem('UserData2', JSON.stringify(response));
                return dispatch({
                    type: 'LOGIN',
                    payload: response
                });
            } else if (result.status === 200 && response.level === 'Supervisor') {
                await localStorage.setItem('UserData2', JSON.stringify(response));
                return dispatch({
                    type: 'LOGIN',
                    payload: response
                });
            } else if (result.status === 200 && username === '302') {
                await localStorage.setItem('UserData2', JSON.stringify(response));
                return dispatch({
                    type: 'LOGIN',
                    payload: response
                });
            } else if (result.status === 200 && username === '00980') {
                await localStorage.setItem('UserData2', JSON.stringify(response));
                return dispatch({
                    type: 'LOGIN',
                    payload: response
                });
            } else if (result.status === 200 && response.level !== 'Admin') {
                return dispatch({
                    type: 'LOGIN_MESSAGE',
                    payload: 'You\'re not ADMIN or SUPERVISOR'
                });
            } else if (result.status === 401 || result.status === 404 || result.status === 500) {
                return dispatch({
                    type: 'LOGIN_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            return dispatch({
                type: 'LOGIN_MESSAGE',
                payload: error.toString()
            });
        });
};

export const getUser = (level, location, supervisorId) => dispatch => {
    dispatch({
        type: 'GET_USER_LOADING'
    });
    fetch(`${domain}/api/user?level=${level}&location=${location}&supervisorId=${supervisorId}`, {
        method: 'GET'
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                return dispatch({
                    type: 'GET_USER',
                    payload: response.data
                });
            } else if (result.status === 500) {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'GET_USER_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'GET_USER_MESSAGE',
                payload: error.toString()
            });
        });
};

export const addUser = (name, nip, password, position, level, locationId, timeIn, timeOut, isHeadquarters, supervisorId, mutipleShift, resumeUrl, token, those) => dispatch => {
    dispatch({
        type: 'ADD_USER_LOADING'
    });
    fetch(`${domain}/api/user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            name,
            nip,
            password,
            position,
            level,
            locationId,
            timeIn,
            timeOut,
            isHeadquarters,
            supervisorId,
            mutipleShift,
            resumeUrl,
        })
    })
        .then(async result => {
            const response = await result.json();
            those.props.getUser(those.state.filterLevel, those.state.filterLocation, those.state.filterSupervisor);
            if (result.status === 201) {
                those.setState({
                    add: false,
                    update: false,
                    id: '',
                    name: '',
                    nip: '',
                    password: '',
                    level: '',
                    locationId: '',
                    timeIn: '',
                    timeOut: '',
                    position: '',
                    isHeadquarters: false,
                    supervisorId: '',
                    mutipleShift: false,
                    resumeUrl: '',
                });
                return dispatch({
                    type: 'ADD_USER'
                });
            } else if (result.status === 500) {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'ADD_USER_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'ADD_USER_MESSAGE',
                payload: error.toString()
            });
        });
};

export const updateUser = (id, name, nip, password, position, level, locationId, timeIn, timeOut, isHeadquarters, supervisorId, mutipleShift, file, resumeUrl, token, those) => dispatch => {
    dispatch({
        type: 'UPDATE_USER_LOADING'
    });
    const formData = new FormData();
    formData.append('name', name);
    formData.append('nip', nip);
    formData.append('password', password);
    formData.append('position', position);
    formData.append('level', level);
    formData.append('locationId', locationId);
    formData.append('timeIn', timeIn);
    formData.append('timeOut', timeOut);
    formData.append('isHeadquarters', isHeadquarters);
    formData.append('supervisorId', supervisorId);
    formData.append('mutipleShift', mutipleShift);
    formData.append('resumeUrl', resumeUrl);
    if (file) {
        formData.append('avatar', file, file.name);
    }
    fetch(`${domain}/api/user/${id}`, {
        method: 'PUT',
        headers: {
            'Authorization': token
        },
        body: formData
    })
        .then(async result => {
            const response = await result.json();
            those.props.getUser(those.state.filterLevel, those.state.filterLocation, those.state.filterSupervisor);
            if (result.status === 200) {
                those.setState({
                    add: false,
                    update: false,
                    id: '',
                    name: '',
                    nip: '',
                    password: '',
                    level: '',
                    locationId: '',
                    timeIn: '',
                    timeOut: '',
                    position: '',
                    isHeadquarters: false,
                    supervisorId: '',
                    mutipleShift: false,
                    resumeUrl: '',
                });
                return dispatch({
                    type: 'UPDATE_USER'
                });
            } else if (result.status === 401 || result.status === 404 || result.status === 500) {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'UPDATE_USER_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'UPDATE_USER_MESSAGE',
                payload: error.toString()
            });
        });
};

export const deleteUser = (id, token, those) => dispatch => {
    dispatch({
        type: 'DELETE_USER_LOADING'
    });
    fetch(`${domain}/api/user/${id}`, {
        method: 'DELETE',
        headers: {
            'Authorization': token
        }
    })
        .then(async result => {
            const response = await result.json();
            those.props.getUser(those.state.filterLevel, those.state.filterLocation, those.state.filterSupervisor);
            if (result.status === 200) {
                return dispatch({
                    type: 'DELETE_USER'
                });
            } else if (result.status === 401 || result.status === 404 || result.status === 500) {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'DELETE_USER_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'DELETE_USER_MESSAGE',
                payload: error.toString()
            });
        });
};

export const getSupervisor = () => dispatch => {
    dispatch({
        type: 'GET_SUPERVISOR_LOADING'
    });
    fetch(`${domain}/api/user?level=Supervisor`, {
        method: 'GET'
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                return dispatch({
                    type: 'GET_SUPERVISOR',
                    payload: response.data
                });
            } else if (result.status === 500) {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'GET_SUPERVISOR_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'GET_SUPERVISOR_MESSAGE',
                payload: error.toString()
            });
        });
};
