import {
    domain
} from '../../constants';

export const getRequest = () => dispatch => {
    dispatch({
        type: 'GET_REQUEST_LOADING'
    });
    fetch(`${domain}/api/request`, {
        method: 'GET'
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                return dispatch({
                    type: 'GET_REQUEST',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'GET_REQUEST_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'GET_REQUEST_MESSAGE',
                payload: error.toString()
            });
        });
};

export const addRequest = (locationId, items, period, type, unlockCode, those, token) => dispatch => {
    dispatch({
        type: 'ADD_REQUEST_LOADING'
    });
    fetch(`${domain}/api/request`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            locationId,
            items,
            period,
            type,
            unlockCode
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 201) {
                those.reload();
                those.setState({
                    add: false,
                    update: false,
                    id: '',
                    locationId: '',
                    items: [],
                    location: '',
                    period: '',
                    type: '',
                    filterType: '',
                    unlockCode: ''
                })
                return dispatch({
                    type: 'ADD_REQUEST'
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'ADD_REQUEST_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'ADD_REQUEST_MESSAGE',
                payload: error.toString()
            });
        });
};

export const updateRequest = (id, locationId, items, period, type, those, token) => dispatch => {
    dispatch({
        type: 'UPDATE_REQUEST_LOADING'
    });
    fetch(`${domain}/api/request/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            locationId,
            items,
            period,
            type
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                those.reload();
                those.setState({
                    add: false,
                    update: false,
                    id: '',
                    locationId: '',
                    items: [],
                    location: '',
                    period: '',
                    type: '',
                    filterType: ''
                })
                return dispatch({
                    type: 'UPDATE_REQUEST'
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'UPDATE_REQUEST_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'UPDATE_REQUEST_MESSAGE',
                payload: error.toString()
            });
        });
};

export const deleteRequest = (id, those, token) => dispatch => {
    dispatch({
        type: 'DELETE_REQUEST_LOADING'
    });
    fetch(`${domain}/api/request/${id}`, {
        method: 'DELETE',
        headers: {
            'Authorization': token
        }
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                those.reload();
                return dispatch({
                    type: 'DELETE_REQUEST'
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'DELETE_REQUEST_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'DELETE_REQUEST_MESSAGE',
                payload: error.toString()
            });
        });
};

export const acceptRequest = (id, those, token) => dispatch => {
    dispatch({
        type: 'ACCEPT_REQUEST_LOADING'
    });
    fetch(`${domain}/api/request/${id}/accept`, {
        method: 'DELETE',
        headers: {
            'Authorization': token
        }
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                those.reload();
                return dispatch({
                    type: 'ACCEPT_REQUEST'
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'ACCEPT_REQUEST_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'ACCEPT_REQUEST_MESSAGE',
                payload: error.toString()
            });
        });
};
