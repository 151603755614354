import {
    domain
} from '../../constants';

export const getRequestHistory = period => dispatch => {
    dispatch({
        type: 'GET_REQUEST_HISTORY_LOADING'
    });
    fetch(`${domain}/api/request-history?period=${period}`, {
        method: 'GET'
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                return dispatch({
                    type: 'GET_REQUEST_HISTORY',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'GET_REQUEST_HISTORY_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'GET_REQUEST_HISTORY_MESSAGE',
                payload: error.toString()
            });
        });
};

export const generateRequestCode = () => dispatch => {
    fetch(`${domain}/api/request/generate/request/code`, {
        method: 'GET'
    })
        .then(async result => {
            const response = await result.json();
            alert(response.message)
        })
        .catch(error => {
            alert(error.toString())
        })
}

export const deleteRequestHistory = (id, those, token) => dispatch => {
    dispatch({
        type: 'DELETE_REQUEST_HISTORY_LOADING'
    });
    fetch(`${domain}/api/request-history/${id}`, {
        method: 'DELETE',
        headers: {
            'Authorization': token
        }
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                those.reload();
                return dispatch({
                    type: 'DELETE_REQUEST'
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'DELETE_REQUEST_HISTORY_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'DELETE_REQUEST_HISTORY_MESSAGE',
                payload: error.toString()
            });
        });
};

export const updateRequestHistory = (id, status, estimation, additionalInformation, those, token) => dispatch => {
    dispatch({
        type: 'UPDATE_REQUEST_HISTORY_LOADING'
    });
    fetch(`${domain}/api/request-history/${id}`, {
        method: 'PUT',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            status,
            estimation,
            additionalInformation
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                those.reload();
                return dispatch({
                    type: 'UPDATE_REQUEST'
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'UPDATE_REQUEST_HISTORY_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'UPDATE_REQUEST_HISTORY_MESSAGE',
                payload: error.toString()
            });
        });
};
