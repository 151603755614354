import React, {
  Component
} from 'react';
import {
  connect
} from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";

import Header from './components/Header';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import LocationPage from './pages/LocationPage';
import UserPage from './pages/UserPage';
import AttendancePage from './pages/AttendancePage';
import AnnouncementPage from './pages/AnnouncementPage';
import ItemPage from './pages/ItemPage';
import RequestPage from './pages/RequestPage';
import RequestHistoryPage from './pages/RequestHistoryPage';
import MartPage from './pages/MartPage';
import Uniform from './pages/Uniform';
import RequestUniform from './pages/RequestUniform';
import UserUniformPage from './pages/UserUniformPage';
import {
  initialData
} from './redux/actions';

import { Toaster } from 'react-hot-toast';

const publicUrl = process.env.PUBLIC_URL;

class App extends Component {
  componentDidMount() {
    this.props.initialData();
  }

  render() {
    if (this.props.user.isAuth) {
      return (
        <>
          <Toaster />
          <Router basename={publicUrl}>
            <Header component={
              <Switch>
                <Route exact path='/' component={HomePage} />
                <Route exact path='/location' component={LocationPage} />
                <Route exact path='/user' component={UserPage} />
                <Route exact path='/attendance' component={AttendancePage} />
                <Route exact path='/login' component={LoginPage} />
                <Route exact path='/announcement' component={AnnouncementPage} />
                <Route exact path='/item' component={ItemPage} />
                <Route exact path='/request' component={RequestPage} />
                <Route exact path='/request-history' component={RequestHistoryPage} />
                <Route exact path='/mart' component={MartPage} />
                <Route exact path='/uniform' component={Uniform} />
                <Route exact path='/request-uniform' component={RequestUniform} />
                <Route exact path='/user-uniform' component={UserUniformPage} />
              </Switch>
            } />
          </Router>
        </>
      )
    }
    return (
      <Router basename={publicUrl}>
        <Switch>
          <Route exact path='/login' component={LoginPage} />
        </Switch>
        <Redirect to='/login' />
      </Router>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, {
  initialData
})(App);
