import {
  domain
} from '../../constants'

export const getUserUniform = () => dispatch => {
  dispatch({
    type: 'GET_USER_UNIFORM_LOADING'
  });
  fetch(`${domain}/api/user-uniform`, {
    method: 'GET'
  })
    .then(async result => {
      const response = await result.json();
      if (result.status === 200) {
        return dispatch({
          type: 'GET_USER_UNIFORM',
          payload: response.data
        });
      } else if (result.status === 500) {
        window.scrollTo(0, 0);
        return dispatch({
          type: 'GET_USER_UNIFORM_MESSAGE',
          payload: response.message
        });
      }
    })
    .catch(error => {
      window.scrollTo(0, 0);
      return dispatch({
        type: 'GET_USER_UNIFORM_MESSAGE',
        payload: error.toString()
      });
    });
};

export const addUserUniform = (name, nip, token, those) => dispatch => {
  dispatch({
    type: 'ADD_USER_UNIFORM_LOADING'
  });
  fetch(`${domain}/api/user-uniform`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify({
      name,
      nip,
    })
  })
    .then(async result => {
      const response = await result.json();
      if (result.status === 201) {
        those.reload();
        those.setState({
          add: false,
          update: false,
          id: '',
          name: '',
          nip: ''
        });
        return dispatch({
          type: 'ADD_USER_UNIFORM'
        });
      } else if (result.status === 500) {
        window.scrollTo(0, 0);
        return dispatch({
          type: 'ADD_USER_UNIFORM_MESSAGE',
          payload: response.message
        });
      }
    })
    .catch(error => {
      window.scrollTo(0, 0);
      return dispatch({
        type: 'ADD_USER_UNIFORM_MESSAGE',
        payload: error.toString()
      });
    });
};

export const updateUserUniform = (id, name, nip, token, those) => dispatch => {
  dispatch({
    type: 'UPDATE_USER_UNIFORM_LOADING'
  });
  fetch(`${domain}/api/user-uniform/${id}`, {
    method: 'PUT',
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      nip,
    })
  })
    .then(async result => {
      const response = await result.json();
      if (result.status === 200) {
        those.reload();
        those.setState({
          add: false,
          update: false,
          id: '',
          name: '',
          nip: ''
        });
        return dispatch({
          type: 'UPDATE_USER_UNIFORM'
        });
      } else if (result.status === 401 || result.status === 404 || result.status === 500) {
        window.scrollTo(0, 0);
        return dispatch({
          type: 'UPDATE_USER_UNIFORM_MESSAGE',
          payload: response.message
        });
      }
    })
    .catch(error => {
      window.scrollTo(0, 0);
      return dispatch({
        type: 'UPDATE_USER_UNIFORM_MESSAGE',
        payload: error.toString()
      });
    });
};

export const deleteUserUniform = (id, token, those) => dispatch => {
  dispatch({
    type: 'DELETE_USER_UNIFORM_LOADING'
  });
  fetch(`${domain}/api/user-uniform/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': token
    }
  })
    .then(async result => {
      const response = await result.json();
      if (result.status === 200) {
        those.reload();
        return dispatch({
          type: 'DELETE_USER_UNIFORM'
        });
      } else if (result.status === 401 || result.status === 404 || result.status === 500) {
        window.scrollTo(0, 0);
        return dispatch({
          type: 'DELETE_USER_UNIFORM_MESSAGE',
          payload: response.message
        });
      }
    })
    .catch(error => {
      window.scrollTo(0, 0);
      return dispatch({
        type: 'DELETE_USER_UNIFORM_MESSAGE',
        payload: error.toString()
      });
    });
};
