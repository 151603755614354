import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';

class HomePage extends Component {
    render() {
        return (
            <a style={{ width: '256px' }} rel='noreferrer noopener' target="_blank" href='https://play.google.com/store/apps/details?id=com.reytama.bks&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Temukan di Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/id_badge_web_generic.png' /></a>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {

})(HomePage);
