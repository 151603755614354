import React, {
  Component
} from 'react';
import {
  connect
} from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

import {
  getRequestSupply,
  updateRequestSupply
} from '../redux/actions/requestSupplyAction';

class MartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: moment().startOf('month').toDate(),
      to: moment().endOf('month').toDate(),
      update: false,
      id: '',
      status: '',
      items: []
    };
  }

  componentDidMount() {
    this.reload();
  }

  reload = () => {
    this.props.getRequestSupply(this.state.from.toISOString(), this.state.to.toISOString())
  }

  render() {
    let a = 0;
    this.state.items.forEach(el => {
      a += el.subTotal
    });
    const pending = this.props.requestSupply.getRequestSupply.filter(el => el.status === 'Pending');
    const aggree = this.props.requestSupply.getRequestSupply.filter(el => el.status === 'Disetujui');
    const disaggree = this.props.requestSupply.getRequestSupply.filter(el => el.status === 'Tidak Disetujui');
    return (
      <div className="container-fluid">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="form-group">
            <label style={{ display: 'inline' }}>Dari</label>
            <div className="form-control">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  format='DD-MM-YYYY'
                  value={this.state.from}
                  onChange={async (date) => {
                    await this.setState({ from: new Date(date.toISOString()) });
                    this.reload();
                  }}
                  animateYearScrolling
                  inputVariant='outlined'
                  autoOk
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="form-group">
            <label style={{ display: 'inline' }}>Sampai</label>
            <div className="form-control">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  format='DD-MM-YYYY'
                  value={this.state.to}
                  onChange={async (date) => {
                    await this.setState({ to: new Date(date.toISOString()) });
                    this.reload();
                  }}
                  animateYearScrolling
                  inputVariant='outlined'
                  autoOk
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
        {/* abc */}

        {this.state.update && <form onSubmit={(e) => {
          e.preventDefault();
          this.props.updateRequestSupply(this.state.id, this.state.status, this.props.user.userData.token, this.reload);
          this.setState({ update: false, items: [], id: '', status: '' })
        }}>
          <div className="form-group">
            <label style={{ display: 'inline' }}>Lokasi Harga</label>
            <select value={this.state.status} className="form-control" onChange={e => {
              this.setState({ status: e.target.value });
            }}>
              <option value="Pending" >Pending</option>
              <option value="Disetujui" >Disetujui</option>
              <option value="Tidak Disetujui" >Tidak Disetujui</option>
            </select>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive table--no-card m-b-30">
                <table className="table table-borderless table-striped table-earning">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Nama</th>
                      <th>Unit</th>
                      <th>Harga</th>
                      <th>Kuantitas</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.map((el, index) => (
                      <tr key={el._id + index}>
                        <td>{index + 1}</td>
                        <td>{el.name}</td>
                        <td>{el.unit}</td>
                        <td>Rp. {el.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                        <td>{el.quantity}</td>
                        <td>Rp. {el.subTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <h1>Total: Rp. {a.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h1>
          <button className="au-btn au-btn--block au-btn--green m-b-20" type="submit">Update</button>
          <button className="au-btn au-btn--block au-btn--blue m-b-20" type="submit" onClick={() => this.setState({ update: false, items: [], id: '', status: '' })}>Batal</button>
        </form>}

        {/* def */}
        <div className="row">
          <div className="col-lg-12">
            <h1>Pending</h1>
            <div className="table-responsive table--no-card m-b-30">
              <table className="table table-borderless table-striped table-earning">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama</th>
                    <th>Lokasi Kerja</th>
                    <th>Diajukan Tanggal</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {pending.map((el, index) => (
                    <tr key={el._id}>
                      <td>{index + 1}</td>
                      <td>{el.userId.name} ({el.userId.nip})</td>
                      <td>{el.userId.locationId.name}</td>
                      <td>{moment(el.createdAt).format('LL')}</td>
                      <td>
                        <div className="table-data-feature">
                          <button onClick={() => {
                            window.scrollTo(0, 0);
                            this.setState({ items: el.items, id: el._id, status: el.status, update: true })
                          }} className="item" data-toggle="tooltip" data-placement="top" title="Update">
                            <i className="zmdi zmdi-edit"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <h1>Disetujui</h1>
            <div className="table-responsive table--no-card m-b-30">
              <table className="table table-borderless table-striped table-earning">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama</th>
                    <th>Lokasi Kerja</th>
                    <th>Diajukan Tanggal</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {aggree.map((el, index) => (
                    <tr key={el._id}>
                      <td>{index + 1}</td>
                      <td>{el.userId.name} ({el.userId.nip})</td>
                      <td>{el.userId.locationId.name}</td>
                      <td>{moment(el.createdAt).format('LL')}</td>
                      <td>
                        <div className="table-data-feature">
                          <a href={`https://bks-backend.reynandaptr.dev/api/request-supply/${el._id}`} className="item" data-toggle="tooltip" data-placement="top" title="Download">
                            <i className="zmdi zmdi-download"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <h1>Tidak Disetujui</h1>
            <div className="table-responsive table--no-card m-b-30">
              <table className="table table-borderless table-striped table-earning">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama</th>
                    <th>Lokasi Kerja</th>
                    <th>Diajukan Tanggal</th>
                    {/* <th>Aksi</th> */}
                  </tr>
                </thead>
                <tbody>
                  {disaggree.map((el, index) => (
                    <tr key={el._id}>
                      <td>{index + 1}</td>
                      <td>{el.userId.name} ({el.userId.nip})</td>
                      <td>{el.userId.locationId.name}</td>
                      <td>{moment(el.createdAt).format('LL')}</td>
                      {/* <td>
                        <div className="table-data-feature">
                          <button onClick={() => {
                            window.scrollTo(0, 0);
                          }} className="item" data-toggle="tooltip" data-placement="top" title="Update">
                            <i className="zmdi zmdi-edit"></i>
                          </button>
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  requestSupply: state.requestSupply
});

export default connect(mapStateToProps, {
  getRequestSupply,
  updateRequestSupply
})(MartPage);
