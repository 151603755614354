export default (state = {
    getLocation: [],
    getLocationLoading: false,
    getLocationMessage: '',
    addLocationLoading: false,
    addLocationMessage: '',
    updateLocationLoading: false,
    updateLocationMessage: '',
    deleteLocationLoading: false,
    deleteLocationMessage: '',
    loginLocationLoading: false,
    loginLocationMessage: ''
}, action) => {
    switch (action.type) {
        case 'GET_LOCATION':
            return {
                ...state,
                getLocation: action.payload,
                getLocationLoading: false,
                getLocationMessage: ''
            };
        case 'GET_LOCATION_LOADING':
            return {
                ...state,
                getLocationLoading: true,
                getLocationMessage: ''
            };
        case 'GET_LOCATION_MESSAGE':
            return {
                ...state,
                getLocationLoading: false,
                getLocationMessage: action.payload
            };
        case 'ADD_LOCATION':
            return {
                ...state,
                addLocationLoading: false,
                addLocationMessage: ''
            };
        case 'ADD_LOCATION_LOADING':
            return {
                ...state,
                addLocationLoading: true,
                addLocationMessage: ''
            };
        case 'ADD_LOCATION_MESSAGE':
            return {
                ...state,
                addLocationLoading: false,
                addLocationMessage: action.payload
            };
        case 'UPDATE_LOCATION':
            return {
                ...state,
                updateLocationLoading: false,
                updateLocationMessage: ''
            };
        case 'UPDATE_LOCATION_LOADING':
            return {
                ...state,
                updateLocationLoading: true,
                updateLocationMessage: ''
            };
        case 'UPDATE_LOCATION_MESSAGE':
            return {
                ...state,
                updateLocationLoading: false,
                updateLocationMessage: action.payload
            };
        case 'DELETE_LOCATION':
            return {
                ...state,
                deleteLocationLoading: false,
                deleteLocationMessage: ''
            };
        case 'DELETE_LOCATION_LOADING':
            return {
                ...state,
                deleteLocationLoading: true,
                deleteLocationMessage: ''
            };
        case 'DELETE_LOCATION_MESSAGE':
            return {
                ...state,
                deleteLocationLoading: false,
                deleteLocationMessage: action.payload
            };
        case 'LOGIN_LOCATION':
            return {
                ...state,
                loginLocationLoading: false,
                loginLocationMessage: ''
            };
        case 'LOGIN_LOCATION_LOADING':
            return {
                ...state,
                loginLocationLoading: true,
                loginLocationMessage: ''
            };
        case 'LOGIN_LOCATION_MESSAGE':
            return {
                ...state,
                loginLocationLoading: false,
                loginLocationMessage: action.payload
            };
        default:
            return state;
    }
};
