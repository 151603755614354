import {
    domain
} from '../../constants';

export const getUniform = (type, token) => dispatch => {
    dispatch({
        type: 'GET_UNIFORM_LOADING'
    });
    fetch(`${domain}/api/uniform?type=${type}`, {
        method: 'GET',
        headers: {
            'Authorization': token
        }
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                return dispatch({
                    type: 'GET_UNIFORM',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'GET_UNIFORM_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'GET_UNIFORM_MESSAGE',
                payload: error.toString()
            });
        });
};

export const addUniform = (name, unit, price, type, those, token) => dispatch => {
    dispatch({
        type: 'ADD_UNIFORM_LOADING'
    });
    fetch(`${domain}/api/uniform`, {
        method: 'POST',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name,
            unit,
            price,
            type
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 201) {
                those.reload();
                those.setState({
                    add: false,
                    update: false,
                    name: '',
                    unit: '',
                    price: '',
                })
                return dispatch({
                    type: 'ADD_UNIFORM',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'ADD_UNIFORM_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'ADD_UNIFORM_MESSAGE',
                payload: error.toString()
            });
        });
};

export const updateUniform = (id, name, unit, price, type, those, token) => dispatch => {
    dispatch({
        type: 'UPDATE_UNIFORM_LOADING'
    });
    fetch(`${domain}/api/uniform/${id}`, {
        method: 'PUT',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name,
            unit,
            price,
            type
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                those.reload();
                those.setState({
                    add: false,
                    update: false,
                    id: '',
                    name: '',
                    unit: '',
                    price: '',
                })
                return dispatch({
                    type: 'UPDATE_UNIFORM',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'UPDATE_UNIFORM_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'UPDATE_UNIFORM_MESSAGE',
                payload: error.toString()
            });
        });
};

export const deleteUniform = (id, those, token) => dispatch => {
    dispatch({
        type: 'DELETE_UNIFORM_LOADING'
    });
    fetch(`${domain}/api/uniform/${id}`, {
        method: 'DELETE',
        headers: {
            'Authorization': token
        }
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                those.reload();
                return dispatch({
                    type: 'DELETE_UNIFORM',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'DELETE_UNIFORM_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'DELETE_UNIFORM_MESSAGE',
                payload: error.toString()
            });
        });
};
