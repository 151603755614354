import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';

import {
    getRequest,
    addRequest,
    updateRequest,
    deleteRequest,
    getLocation,
    getItem,
    acceptRequest
} from '../redux/actions';

class RequestPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            update: false,
            id: '',
            locationId: '',
            items: [],
            location: '',
            period: '',
            type: '',
            filterType: '',
            unlockCode: ''
        };
    }

    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.props.getRequest();
        this.props.getLocation();
    }

    render() {
        let a = 0;
        this.state.items.forEach(el => {
            a += el.subTotal
        });
        const filteredItem = this.state.items.filter(el => {
            if (this.state.filterType) {
                return el.type === this.state.filterType
            }
            return el;
        });
        return (
            <div className="container-fluid">
                {this.props.request.addRequestMessage && <div className="alert alert-danger" role="alert">
                    {this.props.request.addRequestMessage}
                </div>}
                <div className="table-data__tool-right">
                    <button onClick={() => {
                        this.setState((state, props) => ({ add: !state.add, update: false, id: '', locationId: '', location: '', period: '', type: '', unlockCode: '' }));
                    }} className="au-btn au-btn-icon au-btn--green au-btn--small">
                        <i className="zmdi zmdi-plus"></i>tambah permintaan
                    </button>
                </div>
                <br />
                {this.state.add || this.state.update ? <form onSubmit={(e) => {
                    e.preventDefault();
                    let newItems = this.state.items.map(el => {
                        return {
                            itemId: el._id,
                            quantity: el.quantity
                        }
                    });
                    newItems = newItems.filter(el => el.quantity > 0);
                    if (newItems.length === 0) {
                        return alert('Anda belum memilih item satupun');
                    }
                    if (this.state.update) {
                        return this.props.updateRequest(this.state.id, this.state.locationId, newItems, this.state.period, this.state.type, this, this.props.user.userData.token);
                    }
                    this.props.addRequest(this.state.locationId, newItems, this.state.period, this.state.type, this.state.unlockCode, this, this.props.user.userData.token);
                }}>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Lokasi</label>
                        <select value={this.state.locationId} className="form-control" onChange={e => {
                            this.setState({ locationId: e.target.value });
                        }} disabled={this.state.update}>
                            <option value="" >Pilih Lokasi</option>
                            {this.props.location.getLocation.map(el => <option key={el._id} value={el._id}>{el.name}</option>)}
                        </select>
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Lokasi Harga</label>
                        <select value={this.state.location} className="form-control" onChange={e => {
                            this.setState({ location: e.target.value });
                        }} disabled={this.state.update}>
                            <option value="" >Pilih Lokasi Harga</option>
                            <option value="Batam" >Batam</option>
                            <option value="Jakarta" >Jakarta</option>
                            <option value="Pekanbaru" >Pekanbaru</option>
                            <option value="Padang" >Padang</option>
                            <option value="Medan" >Medan</option>
                            <option value="Tanjungpinang" >Tanjungpinang</option>
                            <option value="Bandung" >Bandung</option>
                            <option value="Bali" >Bali</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Periode</label>
                        <select value={this.state.period} className="form-control" onChange={e => {
                            this.setState({ period: e.target.value });
                        }} disabled={this.state.update}>
                            <option value="" >Pilih Periode</option>
                            <option value={`Januari, ${new Date().getFullYear()}`} >{`Januari, ${new Date().getFullYear()}`}</option>
                            <option value={`Februari, ${new Date().getFullYear()}`} >{`Februari, ${new Date().getFullYear()}`}</option>
                            <option value={`Maret, ${new Date().getFullYear()}`} >{`Maret, ${new Date().getFullYear()}`}</option>
                            <option value={`April, ${new Date().getFullYear()}`} >{`April, ${new Date().getFullYear()}`}</option>
                            <option value={`Mei, ${new Date().getFullYear()}`} >{`Mei, ${new Date().getFullYear()}`}</option>
                            <option value={`Juni, ${new Date().getFullYear()}`} >{`Juni, ${new Date().getFullYear()}`}</option>
                            <option value={`Juli, ${new Date().getFullYear()}`} >{`Juli, ${new Date().getFullYear()}`}</option>
                            <option value={`Agustus, ${new Date().getFullYear()}`} >{`Agustus, ${new Date().getFullYear()}`}</option>
                            <option value={`September, ${new Date().getFullYear()}`} >{`September, ${new Date().getFullYear()}`}</option>
                            <option value={`Oktober, ${new Date().getFullYear()}`} >{`Oktober, ${new Date().getFullYear()}`}</option>
                            <option value={`November, ${new Date().getFullYear()}`} >{`November, ${new Date().getFullYear()}`}</option>
                            <option value={`Desember, ${new Date().getFullYear()}`} >{`Desember, ${new Date().getFullYear()}`}</option>
                            <option value={`Januari, ${new Date().getFullYear() + 1}`} >{`Januari, ${new Date().getFullYear() + 1}`}</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Jenis</label>
                        <select value={this.state.type} className="form-control" onChange={e => {
                            this.setState({ type: e.target.value });
                        }} disabled={this.state.update}>
                            <option value="" >Pilih Jenis</option>
                            <option value="Reguler" >Reguler</option>
                            <option value="Susulan" >Susulan</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Pilih Tipe</label>
                        <select value={this.state.filterType} className="form-control" onChange={async e => {
                            await this.setState({ items: [], filterType: e.target.value });
                            this.props.getItem(this, true, this.state.location);
                        }}>
                            <option value="" >Pilih Tipe</option>
                            <option value="A" >A. Machinery</option>
                            <option value="B" >B. Non Machinery</option>
                            <option value="C" >C. Seragam</option>
                            <option value="D" >D. Bahan Habis Pakai</option>
                            <option value="E" >E. Alat Tulis Kantor</option>
                            <option value="F" >F. Spare Part</option>
                            <option value="G" >G. SuperKLIN Mart</option>
                        </select>
                    </div>
                    {this.state.add && <div className="form-group">
                        <label style={{ display: 'inline' }}>Unlock Code (optional)</label>
                        <input value={this.state.unlockCode} onChange={(e) => this.setState({ unlockCode: e.target.value })} className="au-input au-input--full" type="text" name="unlockCode" placeholder="Unlock Code" />
                    </div>}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive table--no-card m-b-30">
                                <table className="table table-borderless table-striped table-earning">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Nama</th>
                                            {this.props.user.userData.level === 'Admin' || this.props.user.userData.nip === '302' || this.props.user.userData.level === '00980' ? <th>Harga</th> : null}
                                            <th>Unit</th>
                                            <th>Tipe</th>
                                            <th>Kuantitas</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredItem.map((el, index) => (
                                            <tr key={el._id + index}>
                                                <td>{index + 1}</td>
                                                <td>{el.name}</td>
                                                {this.props.user.userData.level === 'Admin' || this.props.user.userData.nip === '302' || this.props.user.userData.level === '00980' ? <td>Rp. {el.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td> : null}
                                                <td>{el.unit}</td>
                                                <td>{el.type}</td>
                                                <td>{el.quantity}</td>
                                                <td>
                                                    <div className="table-data-feature">
                                                        <button onClick={e => {
                                                            e.preventDefault();
                                                            const newstate = this.state.items.map(element => {
                                                                if (element._id === el._id) {
                                                                    return {
                                                                        ...element,
                                                                        quantity: element.quantity + 1,
                                                                        subTotal: (element.quantity + 1) * element.price
                                                                    };
                                                                }
                                                                return {
                                                                    ...element
                                                                };
                                                            })
                                                            this.setState({ items: newstate });
                                                        }} className="item" data-toggle="tooltip" data-placement="top" title="Update">
                                                            <i className="zmdi zmdi-plus"></i>
                                                        </button>
                                                        <button onClick={e => {
                                                            e.preventDefault();
                                                            if (el.quantity > 0) {
                                                                const newstate = this.state.items.map(element => {
                                                                    if (element._id === el._id) {
                                                                        return {
                                                                            ...element,
                                                                            quantity: element.quantity - 1,
                                                                            subTotal: (element.quantity - 1) * element.price
                                                                        };
                                                                    }
                                                                    return {
                                                                        ...element
                                                                    };
                                                                })
                                                                this.setState({ items: newstate });
                                                            }
                                                        }} className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                                                            <i className="zmdi zmdi-minus"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <h1>Total: Rp. {a.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h1>
                    <button className="au-btn au-btn--block au-btn--green m-b-20" type="submit">{this.state.add ? 'tambah' : 'update'}</button>
                    <button className="au-btn au-btn--block au-btn--blue m-b-20" type="submit" onClick={() => this.setState((state, props) => ({ add: false, update: false, id: '', locationId: '', items: [], location: '', period: '', type: '' }))}>batal</button>
                </form> : null}
                {this.props.user.userData.level === 'Admin' || this.props.user.userData.nip === '302' || this.props.user.userData.level === '00980' ? <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive table--no-card m-b-30">
                            <table className="table table-borderless table-striped table-earning">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Supervisor</th>
                                        <th>Lokasi</th>
                                        <th>Periode</th>
                                        <th>Tipe</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.request.getRequest.map((el, index) => (
                                        <tr key={el._id}>
                                            <td>{index + 1}</td>
                                            <td>{el.supervisorId ? el.supervisorId.name : 'Supervisor tidak diketahui'}</td>
                                            <td>{el.locationId ? el.locationId.name : 'Lokasi tidak diketahui'}</td>
                                            <td>{el.period}</td>
                                            <td>{el.type}</td>
                                            <td>
                                                <div className="table-data-feature">
                                                    <button onClick={() => {
                                                        window.scrollTo(0, 0);
                                                        const newItems = el.items.map(element => {
                                                            return {
                                                                quantity: element.quantity,
                                                                subTotal: element.itemId.price * element.quantity,
                                                                ...element.itemId
                                                            }
                                                        });
                                                        this.setState({ update: true, add: false, locationId: el.locationId._id, items: newItems, id: el._id, period: el.period, type: el.type, location: el.items[0].itemId.location })
                                                    }} className="item" data-toggle="tooltip" data-placement="top" title="Update">
                                                        <i className="zmdi zmdi-edit"></i>
                                                    </button>
                                                    <button onClick={() => this.props.acceptRequest(el._id, this, this.props.user.userData.token)} className="item" data-toggle="tooltip" data-placement="top" title="Accept">
                                                        <i className="zmdi zmdi-check"></i>
                                                    </button>
                                                    <button onClick={() => this.props.deleteRequest(el._id, this, this.props.user.userData.token)} className="item" data-toggle="tooltip" data-placement="top" title="Reject">
                                                        <i className="zmdi zmdi-minus"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> : null}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    request: state.request,
    location: state.location,
    user: state.user
});

export default connect(mapStateToProps, {
    getRequest,
    addRequest,
    updateRequest,
    deleteRequest,
    getLocation,
    getItem,
    acceptRequest
})(RequestPage);
