import {
  combineReducers
} from 'redux';
import userReducer from './userReducer';
import locationReducer from './locationReducer';
import attendanceReducer from './attendanceReducer';
import announcementReducer from './announcementReducer';
import itemReducer from './itemReducer';
import requestReducer from './requestReducer';
import requestHistoryReducer from './requestHistoryReducer';
import requestSupplyReducer from './requestSupplyReducer';
import uniformReducer from './uniformReducer';
import requestUniformReducer from './requestUniformReducer';
import userUniformReducer from './userUniformReducer';

export default combineReducers({
  user: userReducer,
  location: locationReducer,
  attendance: attendanceReducer,
  announcement: announcementReducer,
  item: itemReducer,
  request: requestReducer,
  requestHistory: requestHistoryReducer,
  requestSupply: requestSupplyReducer,
  uniform: uniformReducer,
  requestUniform: requestUniformReducer,
  userUniform: userUniformReducer
});
