import {
    domain
} from '../../constants';

export const getItem = (those, request, location) => dispatch => {
    dispatch({
        type: 'GET_ITEM_LOADING'
    });
    fetch(`${domain}/api/item?location=${location}`, {
        method: 'GET'
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                if (request)  {
                    const newData = response.data.map(el => {
                        return {
                            ...el,
                            quantity: 0,
                            subTotal: 0
                        }
                    });
                    return those.setState({
                        items: newData
                    });
                }
                return dispatch({
                    type: 'GET_ITEM',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'GET_ITEM_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'GET_ITEM_MESSAGE',
                payload: error.toString()
            });
        });
};

export const addItem = (name, unit, price, type, location, those) => dispatch => {
    dispatch({
        type: 'ADD_ITEM_LOADING'
    });
    fetch(`${domain}/api/item`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name,
            unit,
            price,
            type,
            location
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 201) {
                those.reload();
                those.setState({
                    add: false,
                    update: false,
                    id: '',
                    name: '',
                    unit: '',
                    price: '',
                    type: ''
                })
                return dispatch({
                    type: 'ADD_ITEM',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'ADD_ITEM_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'ADD_ITEM_MESSAGE',
                payload: error.toString()
            });
        });
};

export const updateItem = (id, name, unit, price, type, location, code, those) => dispatch => {
    dispatch({
        type: 'UPDATE_ITEM_LOADING'
    });
    fetch(`${domain}/api/item/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name,
            unit,
            price,
            type,
            location,
            code
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                those.reload();
                those.setState({
                    add: false,
                    update: false,
                    id: '',
                    name: '',
                    unit: '',
                    price: '',
                    type: '',
                    code: ''
                })
                return dispatch({
                    type: 'UPDATE_ITEM',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'UPDATE_ITEM_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'UPDATE_ITEM_MESSAGE',
                payload: error.toString()
            });
        });
};

export const deleteItem = (id, those) => dispatch => {
    dispatch({
        type: 'DELETE_ITEM_LOADING'
    });
    fetch(`${domain}/api/item/${id}`, {
        method: 'DELETE'
    })
        .then(async result => {
            const response = await result.json();
            those.reload();
            if (result.status === 200) {
                those.reload();
                return dispatch({
                    type: 'DELETE_ITEM',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'DELETE_ITEM_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'DELETE_ITEM_MESSAGE',
                payload: error.toString()
            });
        });
};
