import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";

interface HeaderProps {
    /**
    * Render component inside header
    */
    component: React.Component;
}

class Header extends Component<HeaderProps> {
    render() {
        return (
            <div>
                {this.props.attendance.getAttendanceReportLoading ? <div style={{ zIndex: 5, width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed' }}>
                    <h4 style={{ textAlign: 'center', color: '#fff' }}>Loading...</h4>
                </div> : null}
                <div className="page-wrapper">
                    <header className="header-mobile d-block d-lg-none">
                        <div className="header-mobile__bar">
                            <div className="container-fluid">
                                <div className="header-mobile-inner">
                                    <a className="logo" href="/">
                                        <img src="/images/icon/logo.png" alt="CoolAdmin" style={{ width: '50%' }} />
                                    </a>
                                    <button className="hamburger hamburger--slider" type="button">
                                        <span className="hamburger-box">
                                            <span className="hamburger-inner"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <nav className="navbar-mobile">
                            <div className="container-fluid">
                                <ul className="navbar-mobile__list list-unstyled">
                                    <li>
                                        <a href="/">
                                            <i className="fas fa-home"></i>Home</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </header>
                    <aside className="menu-sidebar d-none d-lg-block">
                        <div className="logo">
                            <a href="/">
                                <img src="/images/icon/logo.png" alt="Cool Admin" style={{ width: '50%' }} />
                            </a>
                        </div>
                        <div className="menu-sidebar__content js-scrollbar1">
                            <nav className="navbar-sidebar">
                                {!this.props.user.userData.isClient ? (
                                    <ul className="list-unstyled navbar__list">
                                        <li>
                                            <Link to='/'>
                                                <i className="fas fa-home"></i>Home</Link>
                                        </li>
                                        {this.props.user.userData.level === 'Admin' && <li>
                                            <Link to='/location'>
                                                <i className="fas fa-map-marker"></i>Lokasi</Link>
                                        </li>}
                                        {this.props.user.userData.nip !== '302' && this.props.user.userData.level !== '00980' ? <li>
                                            <Link to='/user'>
                                                <i className="fas fa-user"></i>Karyawan</Link>
                                        </li> : null}
                                        {this.props.user.userData.level === 'Admin' && <li>
                                            <Link to='/attendance'>
                                                <i className="fas fa-file-excel"></i>Absensi</Link>
                                        </li>}
                                        {this.props.user.userData.level === 'Admin' && <li>
                                            <Link to='/announcement'>
                                                <i className="fas fa-info"></i>Pengumuman</Link>
                                        </li>}
                                        {this.props.user.userData.level === 'Admin' || this.props.user.userData.nip === '302' || this.props.user.userData.level === '00980' ? <li>
                                            <Link to='/item'>
                                                <i className="fas fa-cubes"></i>Menu MR</Link>
                                        </li> : null}
                                        <li>
                                            <Link to='/request'>
                                                <i className="fas fa-shipping-fast"></i>MR &amp; Rekapitulasi</Link>
                                        </li>
                                        {this.props.user.userData.level === 'Admin' || this.props.user.userData.nip === '302' || this.props.user.userData.level === '00980' ? <li>
                                            <Link to='/request-history'>
                                                <i className="fas fa-history"></i>History MR</Link>
                                        </li> : null}
                                        {this.props.user.userData.level === 'Admin' && <li>
                                            <Link to='/mart'>
                                                <i className="fas fa-cart-plus"></i>SuperKlinMart</Link>
                                        </li>}
                                        {this.props.user.userData.level === 'Admin' || this.props.user.userData.nip === '302' || this.props.user.userData.level === '00980' ? <li>
                                            <Link to='/uniform'>
                                                <i className="fas fa-tshirt"></i>Uniform</Link>
                                        </li> : null}
                                        {this.props.user.userData.level === 'Admin' || this.props.user.userData.nip === '302' || this.props.user.userData.level === '00980' ? <li>
                                            <Link to='/user-uniform'>
                                                <i className="fas fa-user"></i>User</Link>
                                        </li> : null}
                                        <li>
                                            <Link to='/request-uniform'>
                                                <i className="fas fa-tshirt"></i>Request Uniform</Link>
                                        </li>
                                    </ul>
                                ) : (
                                    <ul className="list-unstyled navbar__list">
                                        <li>
                                            <Link to='/user'>
                                                <i className="fas fa-user"></i>Karyawan</Link>
                                        </li>
                                        <li>
                                            <Link to='/attendance'>
                                                <i className="fas fa-file-excel"></i>Absensi</Link>
                                        </li>
                                    </ul>
                                )}
                            </nav>
                        </div>
                    </aside>
                    <div className="page-container">
                        <header className="header-desktop">
                            <div className="section__content section__content--p30">
                                <div className="container-fluid">
                                    <div className="header-wrap">
                                        <form className="form-header" action="" method="POST">
                                        </form>
                                        <div className="header-button">
                                            <div className="account-wrap">
                                                <div className="account-item clearfix js-item-menu">
                                                    <div className="image">
                                                        <img src="/images/icon/avatar-big-01.png" alt={this.props.user.userData ? this.props.user.userData.name : ''} />
                                                    </div>
                                                    <div className="content">
                                                        <button onClick={async () => {
                                                            await localStorage.clear();
                                                            return window.location.reload();
                                                        }} className="js-acc-btn">Logout</button>
                                                    </div>
                                                    <div className="account-dropdown js-dropdown">
                                                        <div className="info clearfix">
                                                            <div className="image">
                                                                <button>
                                                                    <img src="/images/icon/avatar-big-01.png" alt={this.props.user.userData ? this.props.user.userData.name : ''} />
                                                                </button>
                                                            </div>
                                                            <div className="content">
                                                                <h5 className="name">
                                                                    <button>{this.props.user.userData ? this.props.user.userData.name : ''}</button>
                                                                </h5>
                                                                <span className="email">{this.props.user.userData ? this.props.user.userData.nip : ''}</span>
                                                            </div>
                                                        </div>
                                                        <div className="account-dropdown__footer">
                                                            <a href="/logout">
                                                                <i className="zmdi zmdi-power"></i>Logout</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="main-content">
                            <div className="section__content section__content--p30">
                                {this.props.component}
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    attendance: state.attendance
});

export default connect(mapStateToProps, null)(Header);
