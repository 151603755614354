export default (state = {
    getAttendance: [],
    getAttendanceLoading: false,
    getAttendanceMessage: '',
    getAttendanceReportLoading: false,
    getAttendanceReportMessage: '',
}, action) => {
    switch (action.type) {
        case 'GET_ATTENDANCE':
            return {
                ...state,
                getAttendance: action.payload,
                getAttendanceLoading: false,
                getAttendanceMessage: ''
            };
        case 'GET_ATTENDANCE_LOADING':
            return {
                ...state,
                getAttendanceLoading: true,
                getAttendanceMessage: ''
            };
        case 'GET_ATTENDANCE_MESSAGE':
            return {
                ...state,
                getAttendanceLoading: false,
                getAttendanceMessage: action.payload
            };
        case 'GET_ATTENDANCE_REPORT':
            return {
                ...state,
                getAttendanceReportLoading: false,
                getAttendanceReportMessage: ''
            };
        case 'GET_ATTENDANCE_REPORT_LOADING':
            return {
                ...state,
                getAttendanceReportLoading: true,
                getAttendanceReportMessage: ''
            };
        case 'GET_ATTENDANCE_REPORT_MESSAGE':
            return {
                ...state,
                getAttendanceReportLoading: false,
                getAttendanceReportMessage: action.payload
            };
        default:
            return state;
    }
};
