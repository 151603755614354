export default (state = {
  getRequestSupply: [],
  getRequestSupplyLoading: false,
  getRequestSupplyMessage: '',
  updateRequestSupplyLoading: false,
  updateRequestSupplyMessage: '',
}, action) => {
  switch (action.type) {
    case 'GET_REQUEST_SUPPLY':
      return {
        ...state,
        getRequestSupply: action.payload,
        getRequestSupplyLoading: false,
        getRequestSupplyMessage: ''
      };
    case 'GET_REQUEST_SUPPLY_LOADING':
      return {
        ...state,
        getRequestSupplyLoading: true,
        getRequestSupplyMessage: ''
      };
    case 'GET_REQUEST_SUPPLY_MESSAGE':
      return {
        ...state,
        getRequestSupplyLoading: false,
        getRequestSupplyMessage: action.payload
      };

    case 'UPDATE_REQUEST_SUPPLY':
      return {
        ...state,
        updateRequestSupplyLoading: false,
        updateRequestSupplyMessage: ''
      };
    case 'UPDATE_REQUEST_SUPPLY_LOADING':
      return {
        ...state,
        updateRequestSupplyLoading: true,
        updateRequestSupplyMessage: ''
      };
    case 'UPDATE_REQUEST_SUPPLY_MESSAGE':
      return {
        ...state,
        updateRequestSupplyLoading: false,
        updateRequestSupplyMessage: action.payload
      };
    default:
      return state;
  }
};
