import React, {
  Component
} from 'react';
import {
  connect
} from 'react-redux';

import {
  getUserUniform,
  addUserUniform,
  updateUserUniform,
  deleteUserUniform,
} from '../redux/actions';

class UserUniformPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
      update: false,
      id: '',
      name: '',
      nip: '',
    };
  }

  componentDidMount() {
    this.reload();
  }

  reload = () => {
    this.props.getUserUniform();
  }

  onAddUser = () => {
    if (this.state.update) {
      return this.props.updateUserUniform(this.state.id, this.state.name, this.state.nip, this.props.user.userData.token, this);
    }
    this.props.addUserUniform(this.state.name, this.state.nip, this.props.user.userData.token, this);
  }

  render() {
    return (
      <div className="container-fluid">
        {this.props.userUniform.addUserUniformMessage && <div className="alert alert-danger" role="alert">
          {this.props.userUniform.addUserUniformMessage}
        </div>}
        {<div className="table-data__tool-right">
          <button onClick={() => this.setState((state, props) => ({
            add: !state.add, update: false, id: '', name: '', nip: ''
          }))} className="au-btn au-btn-icon au-btn--green au-btn--small">
            <i className="zmdi zmdi-plus"></i>add user
                    </button>
        </div>}
        <br />
        {this.state.add || this.state.update ? <form onSubmit={(e) => {
          e.preventDefault();
          this.onAddUser();
        }}>
          <div>
            <div className="form-group">
              <label style={{ display: 'inline' }}>Nama<label style={{ display: 'inline', color: 'red' }}> *</label></label>
              <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} className="au-input au-input--full" type="text" name="nama" placeholder="Nama" />
            </div>
            <div className="form-group">
              <label style={{ display: 'inline' }}>NIK<label style={{ display: 'inline', color: 'red' }}> *</label></label>
              <input disabled={this.state.update ? false : false} value={this.state.nip} onChange={(e) => this.setState({ nip: e.target.value })} className="au-input au-input--full" type="text" name="nip" placeholder="NIK" />
            </div>
          </div>
          <button className="au-btn au-btn--block au-btn--green m-b-20" type="submit">{this.state.add ? 'tambah' : 'update'}</button>
          <button className="au-btn au-btn--block au-btn--blue m-b-20" type="submit" onClick={() => this.setState((state, props) => ({ add: false, update: false, id: '', name: '', nip: '' }))}>batal</button>
        </form> : null}
        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive table--no-card m-b-30">
              <table className="table table-borderless table-striped table-earning">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>NIK</th>
                    <th>Nama</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.userUniform.getUserUniform.map((el, index) => (
                    <tr key={el._id}>
                      <td>{index + 1}</td>
                      <td>{el.nip}</td>
                      <td>{el.name}</td>
                      <td>
                        <div className="table-data-feature">
                          <button onClick={() => {
                            if (el.level === 'Admin') {
                              return alert('Tidak bisa mengubah data Admin');
                            }
                            this.setState({ add: false, update: true, id: el._id, nip: el.nip, name: el.name });
                            window.scrollTo(0, 0);
                          }} className="item" data-toggle="tooltip" data-placement="top" title="Update">
                            <i className="zmdi zmdi-edit"></i>
                          </button>
                          <button onClick={() => this.props.deleteUserUniform(el._id, this.props.user.userData.token, this)} className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                            <i className="zmdi zmdi-delete"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  userUniform: state.userUniform,
  location: state.location
});

export default connect(mapStateToProps, {
  getUserUniform,
  addUserUniform,
  updateUserUniform,
  deleteUserUniform,
})(UserUniformPage);
