import React, { useState, useEffect, useRef } from 'react';
import {
  connect
} from 'react-redux';
import moment from 'moment';
import 'moment/locale/id';

import {
  getUniform,
  getLocation,
  addRequestUniform,
  updateRequestUniform,
  getRequestRequestUniform,
  getUser,
  deleteRequestUniform,
  updateRequestUniformFile,
  updateRequestUniformStatus,
  getUserUniform
} from '../redux/actions';
import { domain } from '../constants';

const RequestUniform = ({
  getUniform,
  user,
  uniform,
  requestUniform,
  getLocation,
  location,
  addRequestUniform,
  updateRequestUniform,
  getRequestRequestUniform,
  getUser,
  deleteRequestUniform,
  updateRequestUniformFile,
  updateRequestUniformStatus,
  getUserUniform,
  userUniform
}) => {
  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState('');
  const [form, setForm] = useState({
    id: '',
    requestLocation: '',
    location: {},
    locationIndex: null,
    supervisorId: {},
    type: '',
    uniforms: [],
    s3Path: ''
  });
  const [uniforms, setUniforms] = useState({
    isNewUser: null,
    user: {},
    userIndex: -1,
    uniform: {},
    uniformIndex: -1,
    size: '',
    quantity: '',
    total: 0
  });
  const [status, setStatus] = useState('Process Signatures');
  const [month, setMonth] = useState(moment().format('MMMM'));
  const [year, setYear] = useState(moment().format('YYYY'));
  const [years, setYears] = useState([]);
  const statuses = ['Accepted', 'Rejected', 'Processing', 'Process Signatures']
  const fileInput = useRef()

  useEffect(() => {
    if (user.userData.level === 'Admin' || user.userData.nip === '302' || user.userData.level === '00980') {
      getRequestRequestUniform(form.requestLocation, '', status, `${month}, ${year}`, user.userData.token);
    } else {
      getRequestRequestUniform(form.requestLocation, user.userData.id, status, `${month}, ${year}`, user.userData.token);
    }
  }, [form.requestLocation, getRequestRequestUniform, month, status, user.userData.id, user.userData.level, user.userData.nip, user.userData.token, year])

  useEffect(() => {
    var startYear = 2019;
    var currentYear = moment().add(3, 'month').year(); // Get the current year

    var yearsArray = [];

    for (var year = startYear; year <= currentYear; year++) {
        yearsArray.push(year);
    }

    setYears(yearsArray);
  }, [])

  useEffect(() => {
    getLocation();
    getUserUniform();
  }, [getLocation, getUserUniform])

  useEffect(() => {
    getUniform(form.requestLocation, user.userData.token);
  }, [form.requestLocation, getUniform, user.userData.token])

  useEffect(() => {
    if (user.userData) {
      const data = user.userData;
      setForm({
        ...form,
        supervisorId: {
          id: data.id,
          nip: data.nip,
          name: data.name
        }
      })
    }
  }, [user.userData]);

  useEffect(() => {
    if (form.supervisorId.id) {
      getUser('', '', form.supervisorId.id)
    }
  }, [form.location, form.supervisorId, getUser])

  const resetForm = () => {
    setForm({
      ...form,
      id: '',
      requestLocation: '',
      location: {},
      locationIndex: null,
      type: '',
      uniforms: [],
      s3Path: ''
    });
  }

  const resetUniform = () => {
    setUniforms({
      isNewUser: null,
      user: {},
      userIndex: -1,
      uniform: {},
      uniformIndex: -1,
      size: '',
      quantity: '',
      total: 0
    })
  }

  return (
    <div className="container-fluid">
      {requestUniform.addUniformMessage && <div className="alert alert-danger" role="alert">
        {requestUniform.addUniformMessage}
      </div>}
      <div className="table-data__tool-right">
        <button onClick={() => {
          setShowForm(true);
          setMode('add')
        }} className="au-btn au-btn-icon au-btn--green au-btn--small">
          <i className="zmdi zmdi-plus"></i>tambah request
        </button>
      </div>
      {showForm && (
        <form onSubmit={(e) => {
          e.preventDefault();
        }}>
          {mode === 'update' && <div>
            <div className="form-group">
              <label style={{ display: 'inline' }}>File</label>
              <br />
              <input type="file" ref={fileInput} />
            </div>
            <button className="au-btn au-btn--block au-btn--green m-b-20" onClick={() => {
              updateRequestUniformFile(
                form.id,
                fileInput.current.files[0],
                user.userData.token,
                resetForm,
                resetUniform,
                setMode,
                setShowForm,
              )
            }}>Upload</button>
          </div>}
          <div className="form-group">
            <label style={{ display: 'inline' }}>Lokasi Harga<label style={{ display: 'inline', color: 'red' }}> *</label></label>
            <select disabled={mode === 'add' ? false : true} value={form.requestLocation} className="form-control" onChange={e => setForm({ ...form, requestLocation: e.target.value })}>
              <option value="" >Pilih Lokasi Harga</option>
              <option value="Batam" >Batam</option>
              <option value="Jakarta" >Jakarta</option>
              <option value="Pekanbaru" >Pekanbaru</option>
              <option value="Padang" >Padang</option>
              <option value="Medan" >Medan</option>
              <option value="Tanjungpinang" >Tanjungpinang</option>
              <option value="Bandung" >Bandung</option>
              <option value="Bali" >Bali</option>
            </select>
          </div>
          <div className="form-group">
            <label style={{ display: 'inline' }}>Lokasi<label style={{ display: 'inline', color: 'red' }}> *</label></label>
            <select disabled={mode === 'add' ? false : true} value={mode === 'add' ? form.locationIndex : form.location.name} className="form-control" onChange={(e) => {
              const index = parseInt(e.target.value)
              setForm({
                ...form,
                location: location.getLocation[index],
                locationIndex: index,
              });
            }}>
              <option value="" >Pilih Lokasi</option>
              {location.getLocation.map((el, i) => <option key={el._id} value={mode === 'add' ? i : el.name} >{el.name}</option>)}
            </select>
          </div>
          <div className="form-group">
            <label style={{ display: 'inline' }}>Supervisor<label style={{ display: 'inline', color: 'red' }}> *</label></label>
            <input disabled value={form.supervisorId.name} onChange={(e) => { }} className="au-input au-input--full" type="text" name="name" />
          </div>
          <div className="form-group">
            <label style={{ display: 'inline' }}>Tipe<label style={{ display: 'inline', color: 'red' }}> *</label></label>
            <select disabled={mode === 'add' ? false : true} value={form.type} className="form-control" onChange={e => setForm({ ...form, type: e.target.value })}>
              <option value="" >Pilih Tipe</option>
              <option value="Reguler" >Reguler</option>
              <option value="Susulan" >Susulan</option>
            </select>
          </div>

          <h3>Uniform</h3>
          {uniforms.isNewUser === null || uniforms.isNewUser === false ? <div className="form-group">
            <label style={{ display: 'inline' }}>User Lama<label style={{ display: 'inline', color: 'red' }}> *</label></label>
            <select value={uniforms.userIndex} className="form-control" onChange={(e) => {
              if (e.target.value === '-1') {
                return setUniforms({
                  ...uniforms,
                  isNewUser: null,
                  user: {},
                  userIndex: -1,
                })
              }
              const index = parseInt(e.target.value)
              setUniforms({
                ...uniforms,
                user: user.getUser[index],
                userIndex: index,
                isNewUser: false,
              });
            }}>
              <option value={-1} >Pilih User</option>
              {user.getUser.map((el, i) => <option key={el._id} value={i} >{el.name}</option>)}
            </select>
          </div> : null}
          {uniforms.isNewUser === null || uniforms.isNewUser === true ? <div className="form-group">
            <label style={{ display: 'inline' }}>User Baru<label style={{ display: 'inline', color: 'red' }}> *</label></label>
            <select value={uniforms.userIndex} className="form-control" onChange={(e) => {
              if (e.target.value === '-1') {
                return setUniforms({
                  ...uniforms,
                  isNewUser: null,
                  user: {},
                  userIndex: -1,
                })
              }
              const index = parseInt(e.target.value)
              setUniforms({
                ...uniforms,
                user: userUniform.getUserUniform[index],
                userIndex: index,
                isNewUser: true,
              });
            }}>
              <option value={-1} >Pilih User</option>
              {userUniform.getUserUniform.map((el, i) => <option key={el._id} value={i} >{el.name}</option>)}
            </select>
          </div> : null}
          <div className="form-group">
            <label style={{ display: 'inline' }}>Uniform<label style={{ display: 'inline', color: 'red' }}> *</label></label>
            <select value={uniforms.uniformIndex} className="form-control" onChange={(e) => {
              const index = parseInt(e.target.value)
              setUniforms({
                ...uniforms,
                uniform: uniform.getUniform[index],
                uniformIndex: index,
              });
            }}>
              <option value={-1} >Pilih Uniform</option>
              {uniform.getUniform.map((el, i) => <option key={el._id} value={i} >{el.name}</option>)}
            </select>
          </div>
          <div className="form-group">
            <label style={{ display: 'inline' }}>Ukuran<label style={{ display: 'inline', color: 'red' }}> *</label></label>
            <input value={uniforms.size} onChange={(e) => setUniforms({ ...uniforms, size: e.target.value.toUpperCase() })} className="au-input au-input--full" type="text" name="size" placeholder="Ukuran" />
          </div>
          <div className="form-group">
            <label style={{ display: 'inline' }}>Kuantitas<label style={{ display: 'inline', color: 'red' }}> *</label></label>
            <input value={uniforms.quantity} onChange={(e) => {
              if (!isNaN(e.target.value) && uniforms.uniform.price) {
                setUniforms({ ...uniforms, quantity: e.target.value, total: uniforms.uniform.price * parseInt(e.target.value) });
              }
            }} className="au-input au-input--full" type="text" name="quantity" placeholder="Ukuran" />
          </div>

          <button className="au-btn au-btn--block au-btn--green m-b-20" onClick={() => {
            if (uniforms.userIndex !== -1 && uniforms.uniformIndex !== -1 && uniforms.size !== '' && uniforms.quantity !== '' && uniforms.total !== 0) {
              setForm({
                ...form,
                uniforms: [...form.uniforms, uniforms]
              });
              resetUniform();
            } else {
              alert('cek form sebelum menambah')
            }
          }}>tambah uniform</button>
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive table--no-card m-b-30">
                <table className="table table-borderless table-striped table-earning">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>User</th>
                      <th>Uniform</th>
                      {user.userData.level === 'Admin' || user.userData.nip === '302' || user.userData.level === '00980' ? <th>Harga</th> : null}
                      <th>Size</th>
                      <th>Kuantitas</th>
                      {user.userData.level === 'Admin' || user.userData.nip === '302' || user.userData.level === '00980' ? <th>Total</th> : null}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {form.uniforms.map((el, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{el.user.name}</td>
                        <td>{el.uniform.name}</td>
                        {user.userData.level === 'Admin' || user.userData.nip === '302' || user.userData.level === '00980' ? <td>Rp. {el.uniform.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td> : null}
                        <td>{el.size}</td>
                        <td>{el.quantity}</td>
                        {user.userData.level === 'Admin' || user.userData.nip === '302' || user.userData.level === '00980' ? <td>Rp. {el.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td> : null}
                        <td>
                          <div className="table-data-feature">
                            <button onClick={() => {
                              let temp = Object.assign({}, form);
                              temp.uniforms = temp.uniforms.filter((el, i) => i !== index);
                              setForm(temp);
                            }} className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                              <i className="zmdi zmdi-delete"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <button className="au-btn au-btn--block au-btn--green m-b-20" onClick={(e) => {
            e.preventDefault();
            if (mode === 'add') {
              addRequestUniform(
                form.requestLocation,
                form.location,
                form.supervisorId,
                form.uniforms,
                form.type,
                user.userData.token,
                resetForm,
                resetUniform,
                setMode,
                setShowForm
              )
            } else {
              updateRequestUniform(
                form.id,
                form.requestLocation,
                form.location,
                form.supervisorId,
                form.uniforms,
                form.type,
                user.userData.token,
                resetForm,
                resetUniform,
                setMode,
                setShowForm
              )
            }
          }}>{mode === 'add' ? 'tambah' : 'update'}</button>
          <button className="au-btn au-btn--block au-btn--blue m-b-20" onClick={() => {
            setMode('');
            setShowForm(false);
            resetForm();
            resetUniform();
          }}>batal</button>
        </form>
      )}

      {!showForm && <div>
        <div className="form-group">
          <label style={{ display: 'inline' }}>Lokasi Harga<label style={{ display: 'inline', color: 'red' }}> *</label></label>
          <select value={form.requestLocation} className="form-control" onChange={e => setForm({ ...form, requestLocation: e.target.value })}>
            <option value="" >Pilih Lokasi Harga</option>
            <option value="Batam" >Batam</option>
            <option value="Jakarta" >Jakarta</option>
            <option value="Pekanbaru" >Pekanbaru</option>
            <option value="Padang" >Padang</option>
            <option value="Medan" >Medan</option>
            <option value="Tanjungpinang" >Tanjungpinang</option>
            <option value="Bandung" >Bandung</option>
            <option value="Bali" >Bali</option>
          </select>
        </div>
        <div className="form-group">
          <label style={{ display: 'inline' }}>Status<label style={{ display: 'inline', color: 'red' }}> *</label></label>
          <select value={status} className="form-control" onChange={e => setStatus(e.target.value)}>
            <option value="" >Pilih Status</option>
            {statuses.map((el, i) => <option key={i} value={el} >{el}</option>)}
          </select>
        </div>
        <div className="form-group">
          <label style={{ display: 'inline' }}>Bulan</label>
          <select value={month} className="form-control" onChange={e => {
            setMonth(e.target.value)
          }}>
            <option value="" >Pilih Bulan</option>
            <option value={`Januari`} >{`Januari`}</option>
            <option value={`Februari`} >{`Februari`}</option>
            <option value={`Maret`} >{`Maret`}</option>
            <option value={`April`} >{`April`}</option>
            <option value={`Mei`} >{`Mei`}</option>
            <option value={`Juni`} >{`Juni`}</option>
            <option value={`Juli`} >{`Juli`}</option>
            <option value={`Agustus`} >{`Agustus`}</option>
            <option value={`September`} >{`September`}</option>
            <option value={`Oktober`} >{`Oktober`}</option>
            <option value={`November`} >{`November`}</option>
            <option value={`Desember`} >{`Desember`}</option>
          </select>
        </div>
        <div className="form-group">
          <label style={{ display: 'inline' }}>Tahun</label>
          <select value={year} className="form-control" onChange={e => {
            setYear(e.target.value)
          }}>
            <option value="" >Pilih Tahun</option>
            {years.map(el => <option value={el} >{el}</option>)}
          </select>
        </div>
      </div>}
      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive table--no-card m-b-30">
            <table className="table table-borderless table-striped table-earning">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Supervisor</th>
                  <th>Lokasi</th>
                  <th>Tanggal</th>
                  <th>Tipe</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {requestUniform.getRequestUniform.map((el, index) => (
                  <tr key={el._id}>
                    <td>{index + 1}</td>
                    <td>{el.supervisorId.name}</td>
                    <td>{el.location ? el.location.name : 'Lokasi tidak diketahui'} ({el.requestLocation})</td>
                    <td>{moment(el.createdAt).format('LL')}</td>
                    <td>{el.type}</td>
                    <td>{el.status}</td>
                    <td>
                      <div className="table-data-feature">
                        {((user.userData.level === 'Admin' || user.userData.nip === '302' || user.userData.level === '00980') && (el.status === 'Process Signatures' || el.status === 'Processing')) || (user.userData.nip === el.supervisorId.nip && el.status === 'Process Signatures') ? <button onClick={() => {
                          setShowForm(true);
                          setMode('update');
                          setForm({
                            id: el._id,
                            requestLocation: el.requestLocation,
                            location: el.location,
                            supervisorId: el.supervisorId,
                            type: el.type,
                            uniforms: el.uniforms,
                            s3Path: el.s3Path
                          })
                        }} className="item" data-toggle="tooltip" data-placement="top" title="Update">
                          <i className="zmdi zmdi-edit"></i>
                        </button> : null}
                        {el.status !== 'Process Signatures' && (user.userData.level === 'Admin' || user.userData.nip === '302' || user.userData.level === '00980') ? <a href={`${domain}/api/request-uniform/${el._id}?price=true`} className="item" data-toggle="tooltip" data-placement="top" title="Download">
                          <i className="fas fa-file-excel"></i>
                        </a> : null}
                        {el.status === 'Process Signatures' ? user.userData.level === 'Admin' || user.userData.nip === '302' || user.userData.level === '00980' ? <a href={`${domain}/api/request-uniform/${el._id}?price=true`} className="item" data-toggle="tooltip" data-placement="top" title="Download">
                          <i className="zmdi zmdi-download"></i>
                        </a> : <a href={`${domain}/api/request-uniform/${el._id}`} className="item" data-toggle="tooltip" data-placement="top" title="Download">
                          <i className="zmdi zmdi-download"></i>
                        </a> : <a href={`${domain}/api/request-uniform/${el._id}/file`} className="item" data-toggle="tooltip" data-placement="top" title="Download">
                          <i className="zmdi zmdi-download"></i>
                        </a>}
                        {(user.userData.level === 'Admin' || user.userData.nip === '302' || user.userData.level === '00980') && el.status === 'Processing' && <>
                          <button onClick={() => updateRequestUniformStatus(el._id, 'Accepted', user.userData.token, getRequestRequestUniform, form, user, status, `${month}, ${year}`)} className="item" data-toggle="tooltip" data-placement="top" title="Accept">
                            <i className="zmdi zmdi-check"></i>
                          </button>
                          <button onClick={() => updateRequestUniformStatus(el._id, 'Rejected', user.userData.token, getRequestRequestUniform, form, user, status, `${month}, ${year}`)} className="item" data-toggle="tooltip" data-placement="top" title="Reject">
                            <i className="zmdi zmdi-minus"></i>
                          </button>
                        </>}
                        {(user.userData.level === 'Admin' || user.userData.nip === '302' || user.userData.level === '00980' || user.userData.nip === el.supervisorId.nip) && el.status === 'Process Signatures' ? (
                          <button onClick={() => deleteRequestUniform(el._id, getRequestRequestUniform, form, user, status, `${month}, ${year}`, user.userData.token)} className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                            <i className="zmdi zmdi-delete"></i>
                          </button>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = state => ({
  uniform: state.uniform,
  user: state.user,
  userUniform: state.userUniform,
  requestUniform: state.requestUniform,
  location: state.location,
});

export default connect(mapStateToProps, {
  getUniform,
  getLocation,
  addRequestUniform,
  updateRequestUniform,
  getRequestRequestUniform,
  getUser,
  deleteRequestUniform,
  updateRequestUniformFile,
  updateRequestUniformStatus,
  getUserUniform
})(RequestUniform);
