import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';

import {
    getLocation,
    addLocation,
    updateLocation,
    deleteLocation
} from '../redux/actions';

class LocationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            name: '',
            latitude: 0,
            longitude: 0,
            email: '',
            password: '',
            add: false,
            update: false
        };
    }

    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.props.getLocation();
    }

    onAddLocation = () => {
        if (this.state.add) {
            this.props.addLocation(this.state.name, parseFloat(this.state.latitude), parseFloat(this.state.longitude), this.state.email, this.state.password, this);
        } else {
            this.props.updateLocation(this.state.id, this.state.name, this.state.latitude, this.state.longitude, this.state.email, this.state.password, this);
        }
    }

    render() {
        return (
            <div className="container-fluid">
                {this.props.location.addLocationMessage && <div className="alert alert-danger" role="alert">
                    {this.props.location.addLocationMessage}
                </div>}
                <div className="table-data__tool-right">
                    <button onClick={() => this.setState((state, props) => ({ add: !state.add, update: false, id: '', name: '', latitude: 0, longitude: 0 }))} className="au-btn au-btn-icon au-btn--green au-btn--small">
                        <i className="zmdi zmdi-plus"></i>tambah lokasi
                    </button>
                </div>
                <br />
                {this.state.add || this.state.update ? <form onSubmit={(e) => {
                    e.preventDefault();
                    this.onAddLocation();
                }}>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Nama<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                        <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} className="au-input au-input--full" type="text" name="nama" placeholder="Nama" />
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Latitude<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                        <input value={this.state.latitude} onChange={(e) => this.setState({ latitude: e.target.value })} className="au-input au-input--full" type="text" name="latitude" placeholder="Latitude" />
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Longitude<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                        <input value={this.state.longitude} onChange={(e) => this.setState({ longitude: e.target.value })} className="au-input au-input--full" type="text" name="longitude" placeholder="Longitude" />
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Email<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                        <input value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className="au-input au-input--full" type="text" name="email" placeholder="Email" />
                    </div>

                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Password<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                        <input value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} className="au-input au-input--full" type="password" name="password" placeholder="Password" />
                    </div>
                    <button className="au-btn au-btn--block au-btn--green m-b-20" type="submit">{this.state.add ? 'tambah' : 'update'}</button>
                    <button className="au-btn au-btn--block au-btn--blue m-b-20" type="submit" onClick={() => this.setState((state, props) => ({ add: false, update: false, id: '', name: '', latitude: 0, longitude: 0 }))}>batal</button>
                </form> : null}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive table--no-card m-b-30">
                            <table className="table table-borderless table-striped table-earning">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Nama Lokasi</th>
                                        <th>PIC</th>
                                        <th>Latitude</th>
                                        <th>Longitude</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.location.getLocation.map((el, index) => (
                                        <tr key={el._id}>
                                            <td>{index + 1}</td>
                                            <td>{el.name}</td>
                                            <td>{el.user}</td>
                                            <td>{el.location.coordinates.latitude}</td>
                                            <td>{el.location.coordinates.longitude}</td>
                                            <td>
                                                <div className="table-data-feature">
                                                    <button onClick={() => {
                                                        this.setState({ id: el._id, name: el.name, latitude: el.location.coordinates.latitude, longitude: el.location.coordinates.longitude, add: false, update: true, email: el.user });
                                                        window.scrollTo(0, 0);
                                                    }} className="item" data-toggle="tooltip" data-placement="top" title="Update">
                                                        <i className="zmdi zmdi-edit"></i>
                                                    </button>
                                                    <button onClick={() => this.props.deleteLocation(el._id, this)} className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                                                        <i className="zmdi zmdi-delete"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    location: state.location
});

export default connect(mapStateToProps, {
    getLocation,
    addLocation,
    updateLocation,
    deleteLocation
})(LocationPage);
