import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import toast from 'react-hot-toast';

import {
    getUser,
    addUser,
    updateUser,
    deleteUser,
    getLocation,
    getSupervisor
} from '../redux/actions';
import storage from "../services/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

class UserPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            update: false,
            id: '',
            name: '',
            nip: '',
            password: '',
            level: '',
            locationId: '',
            timeIn: new Date(),
            timeOut: new Date(),
            position: '',
            isHeadquarters: false,
            supervisorId: '',
            filterLevel: '',
            filterLocation: '',
            filterSupervisor: '',
            multipleShift: false,
            resumeUrl: '',
            resumeFile: '',

        };
        this.fileInput = React.createRef();
    }

    componentDidMount() {
        this.reload();
    }

    reload = () => {
        if (this.props.user.userData.isClient) {
            return this.props.getUser(this.state.filterLevel, this.props.user.userData.location_id, "");
        }
        if (this.props.user.userData.level === 'Supervisor') {
            this.props.getUser(this.state.filterLevel, this.state.filterLocation, this.props.user.userData.id);
            this.props.getLocation();
            return this.props.getSupervisor();
        }
        // this.props.getUser(this.state.filterLevel, this.state.filterLocation, this.state.filterSupervisor);
        this.props.getLocation();
        this.props.getSupervisor();
    }

    onAddUser = () => {
        if (this.state.update) {
            return this.props.updateUser(this.state.id, this.state.name, this.state.nip, this.state.password, this.state.position, this.state.level, this.state.locationId, moment(this.state.timeIn).format('HH:mm'), moment(this.state.timeOut).format('HH:mm'), this.state.isHeadquarters, this.state.supervisorId, this.state.multipleShift, this.fileInput.current.files[0], this.state.resumeUrl, this.props.user.userData.token, this);
        }
        this.props.addUser(this.state.name, this.state.nip, this.state.password, this.state.position, this.state.level, this.state.locationId, moment(this.state.timeIn).format('HH:mm'), moment(this.state.timeOut).format('HH:mm'), this.state.isHeadquarters, this.state.supervisorId, this.state.multipleShift, this.state.resumeUrl, this.props.user.userData.token, this);
    }

    handleChange = (event) => {
        this.setState({ resumeFile: event.target.files[0] });
    }

    uploadResume = () => {
        if (!this.state.resumeFile) {
            return toast.error('Please upload an image first!')
        }
        toast('Uploading resume...',)
        setTimeout(() => {
            const storageRef = ref(storage, `/files/${this.state.resumeFile.name}_${moment().unix()}`);
            const uploadTask = uploadBytesResumable(storageRef, this.state.resumeFile);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                },
                (err) => toast.error(err.message),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        this.setState({ resumeUrl: url, resumeFile: '' });
                        console.log(url);
                        toast.success('Resume uploaded successfully!')
                    });
                }
            );
        }, 1000);
    }

    render() {
        return (
            <div className="container-fluid">
                {this.props.user.addUserMessage && <div className="alert alert-danger" role="alert">
                    {this.props.user.addUserMessage}
                </div>}
                {this.props.user.userData.level === 'Admin' && <div className="table-data__tool-right">
                    <button onClick={() => this.setState((state, props) => ({
                        add: !state.add, update: false, id: '', name: '', nip: '', password: '', level: '', locationId: '', timeIn: new Date(), timeOut: new Date(), position: '', isHeadquarters: false, supervisorId: '', multipleShift: false
                    }))} className="au-btn au-btn-icon au-btn--green au-btn--small">
                        <i className="zmdi zmdi-plus"></i>add user
                    </button>
                </div>}
                <br />
                {this.state.add || this.state.update ? <form onSubmit={(e) => {
                    e.preventDefault();
                    this.onAddUser();
                }}>
                    {this.props.user.userData.level === 'Admin' ? <div>
                        {this.state.update && <div className="form-group">
                            <label style={{ display: 'inline' }}>Foto Profil</label>
                            <br />
                            <input type="file" ref={this.fileInput} />
                        </div>}
                        <div className="form-group">
                            <label style={{ display: 'inline' }}>Nama<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                            <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} className="au-input au-input--full" type="text" name="nama" placeholder="Nama" />
                        </div>
                        <div className="form-group">
                            <label style={{ display: 'inline' }}>NIK<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                            <input disabled={this.state.update ? false : false} value={this.state.nip} onChange={(e) => this.setState({ nip: e.target.value })} className="au-input au-input--full" type="text" name="nip" placeholder="NIK" />
                        </div>
                        <div className="form-group">
                            <label style={{ display: 'inline' }}>Password<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                            <input value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} className="au-input au-input--full" type="password" name="password" placeholder="Password" />
                        </div>
                        <div className="form-group">
                            <label style={{ display: 'inline' }}>Jabatan<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                            <input value={this.state.position} onChange={(e) => this.setState({ position: e.target.value })} className="au-input au-input--full" type="text" name="position" placeholder="Jabatan" />
                        </div>
                        <div className="form-group">
                            <label style={{ display: 'inline' }}>Role<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                            <select value={this.state.level} className="form-control" onChange={(e) => this.setState({ level: e.target.value })}>
                                <option value="" >Pilih Role</option>
                                <option value="Employee" >Karyawan</option>
                                <option value="Supervisor" >Supervisor</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label style={{ display: 'inline' }}>Lokasi<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                            <select value={this.state.locationId} className="form-control" onChange={(e) => this.setState({ locationId: e.target.value })}>
                                <option value="" >Pilih Lokasi</option>
                                {this.props.location.getLocation.map(el => <option key={el._id} value={el._id} >{el.name}</option>)}
                            </select>
                        </div>
                        <div className="form-group">
                            <label style={{ display: 'inline' }}>Jam Masuk<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                            <div className="form-control">
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <TimePicker
                                        format='HH:mm'
                                        value={this.state.timeIn}
                                        onChange={(date) => this.setState({ timeIn: new Date(date.toISOString()) })}
                                        animateYearScrolling
                                        inputVariant='outlined'
                                        ampm={false}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="form-group">
                            <label style={{ display: 'inline' }}>Jam Keluar<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                            <div className="form-control">
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <TimePicker
                                        format='HH:mm'
                                        value={this.state.timeOut}
                                        onChange={(date) => this.setState({ timeOut: new Date(date.toISOString()) })}
                                        animateYearScrolling
                                        inputVariant='outlined'
                                        ampm={false}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        {this.state.level !== 'Supervisor' || this.state.update ? <div className="form-group">
                            <label style={{ display: 'inline' }}>Supervisor<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                            <select value={this.state.supervisorId} className="form-control" onChange={(e) => this.setState({ supervisorId: e.target.value })}>
                                <option value="" >Pilih Supervisor</option>
                                <option value="noSupervisor" >Tidak ada Supervisor</option>
                                {this.props.user.getSupervisor.map(el => <option key={el._id} value={el._id} >{el.nip} | {el.name}</option>)}
                            </select>
                        </div> : null}
                        <div className="form-check-inline form-check">
                            <label htmlFor="inline-checkbox1" className="form-check-label ">
                                <input type="checkbox" id="inline-checkbox1" name="inline-checkbox1" checked={this.state.isHeadquarters} onChange={(e) => {
                                    this.setState({ isHeadquarters: e.target.checked });
                                }} className="form-check-input" />HO
                            </label>
                        </div>
                        <div className="form-group">
                            <label style={{ display: 'inline' }}>Resume</label>
                            <br />
                            <input type="file" onChange={this.handleChange} accept="application/pdf" />
                        </div>
                        {this.state.resumeFile && (
                            <button className="au-btn au-btn--inline au-btn--green m-b-20" type="button" onClick={() => {
                                this.uploadResume()
                            }}>Upload</button>)}
                    </div> : <div>
                        {this.state.update && <div className="form-group">
                            <label style={{ display: 'inline' }}>Foto Profil</label>
                            <br />
                            <input type="file" ref={this.fileInput} />
                        </div>}
                        <div className="form-group">
                            <label style={{ display: 'inline' }}>Nama<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                            <input disabled={true} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} className="au-input au-input--full" type="text" name="nama" placeholder="Nama" />
                        </div>
                        <div className="form-group">
                            <label style={{ display: 'inline' }}>Jam Masuk<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                            <div className="form-control">
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <TimePicker
                                        format='HH:mm'
                                        value={this.state.timeIn}
                                        onChange={(date) => this.setState({ timeIn: new Date(date.toISOString()) })}
                                        animateYearScrolling
                                        inputVariant='outlined'
                                        ampm={false}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="form-group">
                            <label style={{ display: 'inline' }}>Jam Keluar<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                            <div className="form-control">
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <TimePicker
                                        format='HH:mm'
                                        value={this.state.timeOut}
                                        onChange={(date) => this.setState({ timeOut: new Date(date.toISOString()) })}
                                        animateYearScrolling
                                        inputVariant='outlined'
                                        ampm={false}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    </div>}
                    <button className="au-btn au-btn--block au-btn--green m-b-20" type="submit">{this.state.add ? 'tambah' : 'update'}</button>
                    <button className="au-btn au-btn--block au-btn--blue m-b-20" type="submit" onClick={() => this.setState((state, props) => ({ add: false, update: false, id: '', name: '', nip: '', password: '', level: '', locationId: '', timeIn: new Date(), timeOut: new Date(), position: '', isHeadquarters: false, supervisorId: '', multipleShift: false }))}>batal</button>
                </form> : null}
                {this.props.user.userData.level === 'Admin' && <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="form-group" style={{ marginRight: '8px' }}>
                        <label style={{ display: 'inline' }}>Filter Role</label>
                        <select value={this.state.filterLevel} className="form-control" onChange={async (e) => {
                            await this.setState({ filterLevel: e.target.value });
                            this.props.getUser(this.state.filterLevel, this.state.filterLocation, this.state.filterSupervisor);
                        }}>
                            <option value="" >Pilih Role</option>
                            <option value="Admin" >Admin</option>
                            <option value="Supervisor" >Supervisor</option>
                            <option value="Employee" >Karyawan</option>
                        </select>
                    </div>
                    <div className="form-group" style={{ marginRight: '8px' }}>
                        <label style={{ display: 'inline' }}>Filter Lokasi</label>
                        <select value={this.state.filterLocation} className="form-control" onChange={async (e) => {
                            await this.setState({ filterLocation: e.target.value });
                            this.props.getUser(this.state.filterLevel, this.state.filterLocation, this.state.filterSupervisor);
                        }}>
                            <option value="" >Pilih Lokasi</option>
                            {this.props.location.getLocation.map(el => <option key={el._id} value={el._id} >{el.name}</option>)}
                        </select>
                    </div>
                    <div className="form-group" style={{ marginRight: '8px' }}>
                        <label style={{ display: 'inline' }}>Filter Supervisor</label>
                        <select value={this.state.filterSupervisor} className="form-control" onChange={async (e) => {
                            await this.setState({ filterSupervisor: e.target.value });
                            this.props.getUser(this.state.filterLevel, this.state.filterLocation, this.state.filterSupervisor);
                        }}>
                            <option value="" >Pilih Supervisor</option>
                            {this.props.user.getSupervisor.map(el => <option key={el._id} value={el._id} >{el.nip} | {el.name}</option>)}
                        </select>
                    </div>
                </div>}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive table--no-card m-b-30">
                            <table className="table table-borderless table-striped table-earning">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>NIK</th>
                                        <th>Nama</th>
                                        <th>Jabatan</th>
                                        <th>Role</th>
                                        <th>Lokasi</th>
                                        <th>Jam Masuk</th>
                                        <th>Jam Keluar</th>
                                        <th>HO</th>
                                        <th>Supervisor</th>
                                        <th>Resume</th>
                                        {/* <th>Multiple Shift</th> */}
                                        {!this.props.user.userData.isClient && <th>Aksi</th>}

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.user.getUser.map((el, index) => (
                                        <tr key={el._id}>
                                            <td>{index + 1}</td>
                                            <td>{el.nip}</td>
                                            <td>{el.name}</td>
                                            <td>{el.position}</td>
                                            <td>{el.level}</td>
                                            <td>{el.locationId ? el.locationId.name : ''}</td>
                                            <td>{el.timeIn}</td>
                                            <td>{el.timeOut}</td>
                                            <td>{el.isHeadquarters ? 'Ya' : 'Tidak'}</td>
                                            <td>{el.supervisorId ? el.supervisorId.name : ''}</td>
                                            <td>{el.resumeUrl && <a href={el.resumeUrl} target='blank'>Lihat resume</a>}</td>
                                            {!this.props.user.userData.isClient &&
                                                <td>
                                                    <div className="table-data-feature">
                                                        <button onClick={() => {
                                                            if (el.level === 'Admin') {
                                                                return alert('Tidak bisa mengubah data Admin');
                                                            }
                                                            this.setState({ add: false, update: true, id: el._id, nip: el.nip, name: el.name, position: el.position, level: el.level, locationId: el.locationId ? el.locationId._id : '', timeIn: moment(el.timeIn, 'HH:mm').toDate(), timeOut: moment(el.timeOut, 'HH:mm').toDate(), isHeadquarters: el.isHeadquarters, supervisorId: el.supervisorId ? el.supervisorId._id : '', multipleShift: el.mutipleShift });
                                                            window.scrollTo(0, 0);
                                                        }} className="item" data-toggle="tooltip" data-placement="top" title="Update">
                                                            <i className="zmdi zmdi-edit"></i>
                                                        </button>
                                                        <button onClick={() => this.props.deleteUser(el._id, this.props.user.userData.token, this)} className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                                                            <i className="zmdi zmdi-delete"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    location: state.location
});

export default connect(mapStateToProps, {
    getUser,
    addUser,
    updateUser,
    deleteUser,
    getLocation,
    getSupervisor
})(UserPage);
