export default (state = {
    getRequest: [],
    getRequestLoading: false,
    getRequestMessage: '',
    addRequestLoading: false,
    addRequestMessage: '',
    updateRequestLoading: false,
    updateRequestMessage: '',
    deleteRequestLoading: false,
    deleteRequestMessage: '',
    acceptRequestLoading: false,
    acceptRequestMessage: '',
}, action) => {
    switch (action.type) {
        case 'GET_REQUEST':
            return {
                ...state,
                getRequest: action.payload,
                getRequestLoading: false,
                getRequestMessage: ''
            };
        case 'GET_REQUEST_LOADING':
            return {
                ...state,
                getRequestLoading: true,
                getRequestMessage: ''
            };
        case 'GET_REQUEST_MESSAGE':
            return {
                ...state,
                getRequestLoading: false,
                getRequestMessage: action.payload
            };
        case 'ADD_REQUEST':
            return {
                ...state,
                addRequestLoading: false,
                addRequestMessage: ''
            };
        case 'ADD_REQUEST_LOADING':
            return {
                ...state,
                addRequestLoading: true,
                addRequestMessage: ''
            };
        case 'ADD_REQUEST_MESSAGE':
            return {
                ...state,
                addRequestLoading: false,
                addRequestMessage: action.payload
            };
        case 'UPDATE_REQUEST':
            return {
                ...state,
                updateRequestLoading: false,
                updateRequestMessage: ''
            };
        case 'UPDATE_REQUEST_LOADING':
            return {
                ...state,
                updateRequestLoading: true,
                updateRequestMessage: ''
            };
        case 'UPDATE_REQUEST_MESSAGE':
            return {
                ...state,
                updateRequestLoading: false,
                updateRequestMessage: action.payload
            };
        case 'DELETE_REQUEST':
            return {
                ...state,
                deleteRequestLoading: false,
                deleteRequestMessage: ''
            };
        case 'DELETE_REQUEST_LOADING':
            return {
                ...state,
                deleteRequestLoading: true,
                deleteRequestMessage: ''
            };
        case 'DELETE_REQUEST_MESSAGE':
            return {
                ...state,
                deleteRequestLoading: false,
                deleteRequestMessage: action.payload
            };
        case 'ACCEPT_REQUEST':
            return {
                ...state,
                acceptRequestLoading: false,
                acceptRequestMessage: ''
            };
        case 'ACCEPT_REQUEST_LOADING':
            return {
                ...state,
                acceptRequestLoading: true,
                acceptRequestMessage: ''
            };
        case 'ACCEPT_REQUEST_MESSAGE':
            return {
                ...state,
                acceptRequestLoading: false,
                acceptRequestMessage: action.payload
            };
        default:
            return state;
    }
};
