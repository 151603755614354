import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@material-ui/pickers';

import {
    getRequestHistory,
    getLocation,
    deleteRequestHistory,
    updateRequestHistory,
    generateRequestCode
} from '../redux/actions';
import { domain } from '../constants';
import moment from 'moment';
import 'moment/locale/id';

class RequestHistoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            update: false,
            id: '',
            status: 'Pending',
            estimation: moment().add(3, 'day').toDate(),
            additionalInformation: '',
            detail: false,
            locationId: '',
            items: [],
            location: '',
            period: '',
            month: moment().format('MMMM'),
            year: moment().format('YYYY'),
            type: '',
            locationItem: '',
            monthItem: moment().format('MMMM'),
            yearItem: moment().format('YYYY'),
            filterLocation: '',
            periodSchedule: '',
            monthSchedule: moment().format('MMMM'),
            yearSchedule: moment().format('YYYY'),
            years: []
        };
    }

    componentDidMount() {
        this.props.getLocation();
        this.reload();
        var startYear = 2019;
        var currentYear = moment().add(3, 'month').year(); // Get the current year

        var yearsArray = [];

        for (var year = startYear; year <= currentYear; year++) {
            yearsArray.push(year);
        }

        this.setState({
            ...this.state,
            years: yearsArray
        })
    }

    reload = () => {
        this.props.getRequestHistory(`${this.state.month}, ${this.state.year}`);
        this.props.getLocation();
    }

    render() {
        let a = 0;
        this.state.items.forEach(el => {
            a += el.subTotal
        });
        let reqHistory = this.props.requestHistory.getRequestHistory;
        if (this.state.filterLocation) {
            reqHistory = this.props.requestHistory.getRequestHistory.filter(el => el.locationId.name === this.state.filterLocation);
        }
        return (
            <div className="container-fluid">
                <a className="table-data__tool-right" href={`${domain}/api/request-history/all/report`}>
                    <button className="au-btn au-btn-icon au-btn--green au-btn--small">
                        <i className="zmdi zmdi-download"></i>download rekap
                    </button>
                </a>
                {this.props.user.userData.nip === '302' || this.props.user.userData.nip === '00980' ? <div style={{ marginTop: '16px' }} className="table-data__tool-right" onClick={() => this.props.generateRequestCode()}>
                    <button className="au-btn au-btn-icon au-btn--green au-btn--small">
                        <i className="zmdi zmdi-download"></i>generate unlock request
                    </button>
                </div> : null}
                <div className="form-group">
                    <label style={{ display: 'inline' }}>Bulan</label>
                    <select value={this.state.month} className="form-control" onChange={e => {
                        this.setState({ month: e.target.value });
                        this.props.getRequestHistory(`${e.target.value}, ${this.state.year}`);
                    }} disabled={this.state.update}>
                        <option value="" >Pilih Bulan</option>
                        <option value={`Januari`} >{`Januari`}</option>
                        <option value={`Februari`} >{`Februari`}</option>
                        <option value={`Maret`} >{`Maret`}</option>
                        <option value={`April`} >{`April`}</option>
                        <option value={`Mei`} >{`Mei`}</option>
                        <option value={`Juni`} >{`Juni`}</option>
                        <option value={`Juli`} >{`Juli`}</option>
                        <option value={`Agustus`} >{`Agustus`}</option>
                        <option value={`September`} >{`September`}</option>
                        <option value={`Oktober`} >{`Oktober`}</option>
                        <option value={`November`} >{`November`}</option>
                        <option value={`Desember`} >{`Desember`}</option>
                    </select>
                </div>
                <div className="form-group">
                    <label style={{ display: 'inline' }}>Tahun</label>
                    <select value={this.state.year} className="form-control" onChange={e => {
                        this.setState({ year: e.target.value });
                        this.props.getRequestHistory(`${this.state.month}, ${e.target.value}`);
                    }} disabled={this.state.update}>
                        <option value="" >Pilih Tahun</option>
                        {this.state.years.map(el => <option value={el} >{el}</option>)}
                    </select>
                </div>
                <div className="form-group" style={{ marginRight: '8px' }}>
                    <label style={{ display: 'inline' }}>Filter Lokasi</label>
                    <select value={this.state.filterLocation} className="form-control" onChange={e => {
                        this.setState({ filterLocation: e.target.value });
                    }}>
                        <option value="" >Pilih Lokasi</option>
                        {this.props.location.getLocation.map(el => <option key={el._id} value={el.name} >{el.name}</option>)}
                    </select>
                </div>
                <br />
                {this.state.detail ? <form onSubmit={(e) => {
                    e.preventDefault();
                }}>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Lokasi</label>
                        <input disabled={this.state.detail} value={this.state.locationId} className="au-input au-input--full" type="text" placeholder="Lokasi" />
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Lokasi Harga</label>
                        <input disabled={this.state.detail} value={this.state.location} className="au-input au-input--full" type="text" placeholder="Lokasi Harga" />
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Periode</label>
                        <input disabled={this.state.detail} value={this.state.period} className="au-input au-input--full" type="text" placeholder="Periode" />
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Jenis</label>
                        <input disabled={this.state.detail} value={this.state.type} className="au-input au-input--full" type="text" placeholder="Jenis" />
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive table--no-card m-b-30">
                                <table className="table table-borderless table-striped table-earning">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Nama</th>
                                            <th>Harga</th>
                                            <th>Unit</th>
                                            <th>Tipe</th>
                                            <th>Kuantitas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.items.map((el, index) => (
                                            <tr key={el._id + index}>
                                                <td>{index + 1}</td>
                                                <td>{el.name}</td>
                                                <td>Rp. {el.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                                <td>{el.unit}</td>
                                                <td>{el.type}</td>
                                                <td>{el.quantity}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <h1>Total: Rp. {a.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h1>
                    <button className="au-btn au-btn--block au-btn--blue m-b-20" type="submit" onClick={() => this.setState((state, props) => ({ detail: false, locationId: '', items: [], location: '', period: '', type: '' }))}>batal</button>
                </form> : null}
                {this.state.update ? <form onSubmit={(e) => {
                    e.preventDefault();
                    this.props.updateRequestHistory(this.state.id, this.state.status, this.state.estimation, this.state.additionalInformation, this, this.props.user.userData.token)
                    this.setState({ update: false, id: '', status: 'Pending', estimation: moment().add(3, 'day').toDate(), additionalInformation: '' })
                }}>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Status<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                        <select value={this.state.status} className="form-control" onChange={(e) => this.setState({ status: e.target.value })}>
                            <option value="" >Pilih Status</option>
                            <option value="Pending" >Pending</option>
                            <option value="On process" >On process</option>
                            <option value="Delivered" >Delivered</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Dari</label>
                        <div className="form-control">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    format='DD-MM-YYYY'
                                    value={this.state.estimation}
                                    onChange={async (date) => {
                                        await this.setState({ estimation: new Date(date.toISOString()) });
                                        this.reload();
                                    }}
                                    animateYearScrolling
                                    inputVariant='outlined'
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'inline' }}>Informasi Tambahan<label style={{ display: 'inline', color: 'red' }}> *</label></label>
                        <input value={this.state.additionalInformation} onChange={(e) => this.setState({ additionalInformation: e.target.value })} className="au-input au-input--full" type="text" name="additionalInformation" placeholder="Informasi Tambahan" />
                    </div>
                    <button className="au-btn au-btn--block au-btn--green m-b-20" type="submit">Update</button>
                    <button className="au-btn au-btn--block au-btn--blue m-b-20" type="submit" onClick={() => this.setState({ update: false, id: '', status: 'Pending', estimation: moment().add(3, 'day').toDate(), additionalInformation: '' })}>batal</button>
                </form> : null}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive table--no-card m-b-30">
                            <table className="table table-borderless table-striped table-earning">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Supervisor</th>
                                        <th>Disetujui Tanggal</th>
                                        <th>Lokasi</th>
                                        <th>Periode</th>
                                        <th>Jenis</th>
                                        <th>Status</th>
                                        <th>Estimasi</th>
                                        {this.props.user.userData.nip === '302' || this.props.user.userData.nip === '00980' ? <th>Informasi Tambahan</th> : null}
                                        {this.props.user.userData.nip === '302' || this.props.user.userData.nip === '00980' ? <th>Update</th> : null}
                                        <th>Aksi</th>
                                        {this.props.user.userData.nip === '302' || this.props.user.userData.nip === '00980' ? <th>Download DO</th> : null}
                                        {this.props.user.userData.nip === '302' || this.props.user.userData.nip === '00980' ? <th>Download PO</th> : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {reqHistory.map((el, index) => (
                                        <tr key={el._id}>
                                            <td>{index + 1}</td>
                                            <td>{el.supervisorId.name}</td>
                                            <td style={{ color: moment(el.createdAt).toDate().getDate() >= 16 ? 'red' : 'black' }}>{moment(el.createdAt).format('ll')}</td>
                                            <td>{el.locationId.name}</td>
                                            <td>{el.period}</td>
                                            <td>{el.type}</td>
                                            <td>{el.status}</td>
                                            <td>{moment(el.estimation).format('ll')}</td>
                                            {this.props.user.userData.nip === '302' || this.props.user.userData.nip === '00980' ? <th>{el.additionalInformation}</th> : null}
                                            {this.props.user.userData.nip === '302' || this.props.user.userData.nip === '00980' ? <td>
                                                <div className="table-data-feature">
                                                    <button onClick={() => {
                                                        this.setState({ update: true, id: el._id, status: el.status, estimation: el.estimation, additionalInformation: el.additionalInformation });
                                                        window.scrollTo(0, 0);
                                                    }} className="item" data-toggle="tooltip" data-placement="top" title="Update">
                                                        <i className="zmdi zmdi-edit"></i>
                                                    </button>
                                                </div>
                                            </td> : null}
                                            <td>
                                                <div className="table-data-feature">
                                                    <button onClick={() => {
                                                        const newItems = el.items.map(element => {
                                                            return {
                                                                quantity: element.quantity,
                                                                subTotal: element.itemId.price * element.quantity,
                                                                ...element.itemId
                                                            }
                                                        });
                                                        window.scrollTo(0, 0);
                                                        this.setState({ detail: true, locationId: el.locationId.name, items: newItems, period: el.period, type: el.type, location: el.items[0].itemId.location })
                                                    }} className="item" data-toggle="tooltip" data-placement="top" title="Reject">
                                                        <i className="zmdi zmdi-eye"></i>
                                                    </button>
                                                    <a href={`https://bks-backend.reynandaptr.dev/api/request-history/${el._id}`} className="item" data-toggle="tooltip" data-placement="top" title="Download">
                                                        <i className="zmdi zmdi-download"></i>
                                                    </a>
                                                    <button onClick={() => this.props.deleteRequestHistory(el._id, this, this.props.user.userData.token)} className="item" data-toggle="tooltip" data-placement="top" title="Reject">
                                                        <i className="zmdi zmdi-minus"></i>
                                                    </button>
                                                </div>
                                            </td>
                                            {this.props.user.userData.nip === '302' || this.props.user.userData.nip === '00980' ? <td>
                                                <div className="table-data-feature">
                                                    <a href={`https://bks-backend.reynandaptr.dev/api/request-history/${el._id}/do`} className="item" data-toggle="tooltip" data-placement="top" title="Download">
                                                        <i className="zmdi zmdi-download"></i>
                                                    </a>
                                                </div>
                                            </td> : null}
                                            {this.props.user.userData.nip === '302' || this.props.user.userData.nip === '00980' ? <td>
                                                <div className="table-data-feature">
                                                    <a href={`https://bks-backend.reynandaptr.dev/api/request-history/${el._id}/invoice`} className="item" data-toggle="tooltip" data-placement="top" title="Download">
                                                        <i className="zmdi zmdi-download"></i>
                                                    </a>
                                                </div>
                                            </td> : null}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label style={{ display: 'inline' }}>Lokasi Harga</label>
                    <select value={this.state.locationItem} className="form-control" onChange={e => {
                        this.setState({ locationItem: e.target.value });
                    }}>
                        <option value="" >Pilih Lokasi Harga</option>
                        <option value="Batam" >Batam</option>
                        <option value="Jakarta" >Jakarta</option>
                        <option value="Pekanbaru" >Pekanbaru</option>
                        <option value="Padang" >Padang</option>
                        <option value="Medan" >Medan</option>
                        <option value="Tanjungpinang" >Tanjungpinang</option>
                        <option value="Bandung" >Bandung</option>
                        <option value="Bali" >Bali</option>
                    </select>
                </div>
                <div className="form-group">
                    <label style={{ display: 'inline' }}>Bulan</label>
                    <select value={this.state.monthItem} className="form-control" onChange={e => {
                        this.setState({ monthItem: e.target.value });
                    }} disabled={this.state.update}>
                        <option value="" >Pilih Bulan</option>
                        <option value={`Januari`} >{`Januari`}</option>
                        <option value={`Februari`} >{`Februari`}</option>
                        <option value={`Maret`} >{`Maret`}</option>
                        <option value={`April`} >{`April`}</option>
                        <option value={`Mei`} >{`Mei`}</option>
                        <option value={`Juni`} >{`Juni`}</option>
                        <option value={`Juli`} >{`Juli`}</option>
                        <option value={`Agustus`} >{`Agustus`}</option>
                        <option value={`September`} >{`September`}</option>
                        <option value={`Oktober`} >{`Oktober`}</option>
                        <option value={`November`} >{`November`}</option>
                        <option value={`Desember`} >{`Desember`}</option>
                    </select>
                </div>
                <div className="form-group">
                    <label style={{ display: 'inline' }}>Tahun</label>
                    <select value={this.state.yearItem} className="form-control" onChange={e => {
                        this.setState({ yearItem: e.target.value });
                    }} disabled={this.state.update}>
                        <option value="" >Pilih Tahun</option>
                        {this.state.years.map(el => <option value={el} >{el}</option>)}
                    </select>
                </div>
                <a className="table-data__tool-right" href={`${domain}/api/item/request?location=${this.state.locationItem}&period=${`${this.state.monthItem}, ${this.state.yearItem}`}`}>
                    <button className="au-btn au-btn-icon au-btn--green au-btn--small">
                        <i className="zmdi zmdi-download"></i>download rekap per item
                    </button>
                </a>
                <div className="form-group">
                    <label style={{ display: 'inline' }}>Bulan</label>
                    <select value={this.state.monthSchedule} className="form-control" onChange={e => {
                        this.setState({ monthSchedule: e.target.value });
                    }} disabled={this.state.update}>
                        <option value="" >Pilih Bulan</option>
                        <option value={`Januari`} >{`Januari`}</option>
                        <option value={`Februari`} >{`Februari`}</option>
                        <option value={`Maret`} >{`Maret`}</option>
                        <option value={`April`} >{`April`}</option>
                        <option value={`Mei`} >{`Mei`}</option>
                        <option value={`Juni`} >{`Juni`}</option>
                        <option value={`Juli`} >{`Juli`}</option>
                        <option value={`Agustus`} >{`Agustus`}</option>
                        <option value={`September`} >{`September`}</option>
                        <option value={`Oktober`} >{`Oktober`}</option>
                        <option value={`November`} >{`November`}</option>
                        <option value={`Desember`} >{`Desember`}</option>
                    </select>
                </div>
                <div className="form-group">
                    <label style={{ display: 'inline' }}>Tahun</label>
                    <select value={this.state.yearSchedule} className="form-control" onChange={e => {
                        this.setState({ yearSchedule: e.target.value });
                    }} disabled={this.state.update}>
                        <option value="" >Pilih Tahun</option>
                        {this.state.years.map(el => <option value={el} >{el}</option>)}
                    </select>
                </div>
                <a className="table-data__tool-right" href={`${domain}/api/request-history/all/schedule?period=${`${this.state.monthSchedule}, ${this.state.yearSchedule}`}`}>
                    <button className="au-btn au-btn-icon au-btn--green au-btn--small">
                        <i className="zmdi zmdi-download"></i>download jadwal
                    </button>
                </a>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    requestHistory: state.requestHistory,
    location: state.location,
    user: state.user
});

export default connect(mapStateToProps, {
    getRequestHistory,
    getLocation,
    deleteRequestHistory,
    updateRequestHistory,
    generateRequestCode
})(RequestHistoryPage);
