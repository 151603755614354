import {
    domain
} from '../../constants';

export const getRequestRequestUniform = (requestLocation, spvID, status, period, token) => dispatch => {
    dispatch({
        type: 'GET_REQUEST_UNIFORM_LOADING'
    });
    fetch(`${domain}/api/request-uniform?requestLocation=${requestLocation}&spvID=${spvID}&status=${status}&period=${period}`, {
        method: 'GET',
        headers: {
            'Authorization': token
        }
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                return dispatch({
                    type: 'GET_REQUEST_UNIFORM',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'GET_REQUEST_UNIFORM_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'GET_REQUEST_UNIFORM_MESSAGE',
                payload: error.toString()
            });
        });
};

export const addRequestUniform = (
    requestLocation,
    location,
    supervisorId,
    uniforms,
    type,
    token,
    resetForm,
    resetUniform,
    setMode,
    setShowForm
) => dispatch => {
    dispatch({
        type: 'ADD_REQUEST_UNIFORM_LOADING'
    });
    fetch(`${domain}/api/request-uniform`, {
        method: 'POST',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            requestLocation,
            location,
            supervisorId,
            uniforms,
            type,
            spvID: supervisorId.id
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 201) {
                setMode('');
                setShowForm(false);
                resetForm();
                resetUniform();
                return dispatch({
                    type: 'ADD_REQUEST_UNIFORM',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'ADD_REQUEST_UNIFORM_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'ADD_REQUEST_UNIFORM_MESSAGE',
                payload: error.toString()
            });
        });
};

export const updateRequestUniform = (
    id,
    requestLocation,
    location,
    supervisorId,
    uniforms,
    type,
    token,
    resetForm,
    resetUniform,
    setMode,
    setShowForm
) => dispatch => {
    dispatch({
        type: 'UPDATE_REQUEST_UNIFORM_LOADING'
    });

    fetch(`${domain}/api/request-uniform/${id}`, {
        method: 'PUT',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            requestLocation,
            location,
            supervisorId,
            uniforms,
            type,
            spvID: supervisorId.id
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                setMode('');
                setShowForm(false);
                resetForm();
                resetUniform();
                return dispatch({
                    type: 'UPDATE_REQUEST_UNIFORM',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'UPDATE_REQUEST_UNIFORM_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'UPDATE_REQUEST_UNIFORM_MESSAGE',
                payload: error.toString()
            });
        });
};

export const deleteRequestUniform = (id, getRequestRequestUniform, form, user, status, period, token) => dispatch => {
    dispatch({
        type: 'DELETE_REQUEST_UNIFORM_LOADING'
    });
    fetch(`${domain}/api/request-uniform/${id}`, {
        method: 'DELETE',
        headers: {
            'Authorization': token
        }
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                if (user.userData.level === 'Admin' || user.userData.nip === '302' || user.userData.level === '00980') {
                    getRequestRequestUniform(form.requestLocation, '', status, period, token);
                } else {
                    getRequestRequestUniform(form.requestLocation, user.userData.id, status, period, token);
                }
                return dispatch({
                    type: 'DELETE_REQUEST_UNIFORM',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'DELETE_REQUEST_UNIFORM_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'DELETE_REQUEST_UNIFORM_MESSAGE',
                payload: error.toString()
            });
        });
};

export const updateRequestUniformStatus = (
    id,
    reqStatus,
    token,
    getRequestRequestUniform,
    form,
    user,
    status,
    period
) => dispatch => {
    dispatch({
        type: 'UPDATE_REQUEST_UNIFORM_STATUS_LOADING'
    });
    fetch(`${domain}/api/request-uniform/${id}/status`, {
        method: 'PUT',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            status: reqStatus
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                if (user.userData.level === 'Admin' || user.userData.nip === '302' || user.userData.level === '00980') {
                    getRequestRequestUniform(form.requestLocation, '', status, period, token);
                } else {
                    getRequestRequestUniform(form.requestLocation, user.userData.id, status, period, token);
                }
                return dispatch({
                    type: 'UPDATE_REQUEST_UNIFORM_STATUS',
                    payload: response.data
                });
            } else {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'UPDATE_REQUEST_UNIFORM_STATUS_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'UPDATE_REQUEST_UNIFORM_STATUS_MESSAGE',
                payload: error.toString()
            });
        });
};

export const updateRequestUniformFile = (
    id,
    file,
    token,
    resetForm,
    resetUniform,
    setMode,
    setShowForm
) => dispatch => {
    const formData = new FormData();
    if (file) {
        formData.append('file', file, file.name);
    }
    fetch(`${domain}/api/request-uniform/${id}/file`, {
        method: 'PUT',
        headers: {
            'Authorization': token
        },
        body: formData
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                setMode('');
                setShowForm(false);
                resetForm();
                resetUniform();
                alert(response.message)
            } else {
                alert(response.message)
            }
        })
        .catch(error => alert(error.toString()));
};
