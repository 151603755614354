import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Initialize Firebase
const app = initializeApp({
  apiKey: "AIzaSyAbRZzz-EijhmQ1TsGtbiQ3zqarCSVjQT4",
  authDomain: "bks-attendance.firebaseapp.com",
  projectId: "bks-attendance",
  storageBucket: "bks-attendance.appspot.com",
  messagingSenderId: "486541845008",
  appId: "1:486541845008:web:262f5c38283096c9832095",
  measurementId: "G-P6ZXP73LBZ"
});

// Firebase storage reference
const storage = getStorage(app);
export default storage;
