import {
    domain
} from '../../constants';

export const getLocation = () => dispatch => {
    dispatch({
        type: 'GET_LOCATION_LOADING'
    });
    fetch(`${domain}/api/location`, {
        method: 'GET'
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                return dispatch({
                    type: 'GET_LOCATION',
                    payload: response.data
                });
            } else if (result.status === 500) {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'GET_LOCATION_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'GET_LOCATION_MESSAGE',
                payload: error.toString()
            });
        });
};

export const addLocation = (name, latitude, longitude, email, password, those) => dispatch => {
    dispatch({
        type: 'ADD_LOCATION_LOADING'
    });
    fetch(`${domain}/api/location/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name,
            location: {
                coordinates: {
                    latitude,
                    longitude
                }
            },
            user: email,
            password
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 201) {
                those.reload();
                those.setState({
                    id: '',
                    name: '',
                    latitude: 0,
                    longitude: 0,
                    add: false,
                    update: false,
                    email: '',
                    password: ''
                });
                return dispatch({
                    type: 'ADD_LOCATION'
                });
            } else if (result.status === 500) {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'ADD_LOCATION_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'ADD_LOCATION_MESSAGE',
                payload: error.toString()
            });
        });
};

export const updateLocation = (id, name, latitude, longitude, email, password, those) => dispatch => {
    dispatch({
        type: 'UPDATE_LOCATION_LOADING'
    });
    fetch(`${domain}/api/location/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name,
            location: {
                coordinates: {
                    latitude,
                    longitude
                }
            },
            user: email,
            password
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 201) {
                those.reload();
                those.setState({
                    id: '',
                    name: '',
                    latitude: 0,
                    longitude: 0,
                    add: false,
                    update: false,
                    email: '',
                    password: ''
                });
                return dispatch({
                    type: 'UPDATE_LOCATION'
                });
            } else if (result.status === 500) {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'UPDATE_LOCATION_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'UPDATE_LOCATION_MESSAGE',
                payload: error.toString()
            });
        });
};

export const deleteLocation = (id, those) => dispatch => {
    dispatch({
        type: 'DELETE_LOCATION_LOADING'
    });
    fetch(`${domain}/api/location/${id}`, {
        method: 'DELETE'
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 201) {
                those.reload();
                return dispatch({
                    type: 'DELETE_LOCATION',
                });
            } else if (result.status === 500) {
                window.scrollTo(0, 0);
                return dispatch({
                    type: 'DELETE_LOCATION_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            window.scrollTo(0, 0);
            return dispatch({
                type: 'DELETE_LOCATION_MESSAGE',
                payload: error.toString()
            });
        });
};

export const loginLocation = (email, password) => dispatch => {
    dispatch({
        type: 'LOGIN_LOCATION_LOADING'
    });
    fetch(`${domain}/api/location/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            user: email,
            password
        })
    })
        .then(async result => {
            const response = await result.json();
            if (result.status === 200) {
                dispatch({
                    type: 'LOGIN_LOCATION',
                });
                await localStorage.setItem('UserData2', JSON.stringify(response));
                return dispatch({
                    type: 'LOGIN',
                    payload: response
                });
            } else if (result.status === 401 || result.status === 404) {
                return dispatch({
                    type: 'LOGIN_LOCATION_MESSAGE',
                    payload: response.message
                });
            }
        })
        .catch(error => {
            return dispatch({
                type: 'LOGIN_LOCATION_MESSAGE',
                payload: error.toString()
            });
        });
};
